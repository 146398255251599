import { ChangeEvent } from 'react'
import { ApiChartOutput } from '../interface/chartsInterface'

export default function Recommendations({
  data,
  setLanguage,
}: {
  data: {
    emissions_total: ApiChartOutput['emissions_total']
    well_done_comments: ApiChartOutput['well_done_comments']
    suggested_actions: ApiChartOutput['suggested_actions']
  }
  setLanguage: any
}) {
  const languages = ['eng', 'fr', 'nl']

  const changeLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
    setLanguage(event.target.value)
  }

  const userType = localStorage.getItem('userType') || 'business'

  return (
    <>
      <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
        {(data.well_done_comments ?? []).length > 0 && <WellDones userType={userType} />}
        <FixedRecommendations userType={userType} data={data} />
      </div>
      <div className="flexRow smText">
        <select className="languageSelector clickable" onChange={changeLanguage}>
          {languages.map((languageKey: string) => {
            return (
              <option key={languageKey} value={languageKey}>
                {languageKey}
              </option>
            )
          })}
        </select>
      </div>
    </>
  )
}

export function WellDones({ userType }: { userType: string }) {
  let well_done_comments
  if (userType == 'business') {
    well_done_comments = ['🎉 Well done - you haven’t spent anything on fuel!']
  } else if (userType == 'consumer') {
    well_done_comments = [
      "🌱 Well done - By choosing plant-based options, you've lowered your carbon footprint and contributed to a greener future.",
    ]
  }

  return (
    <div style={{ width: '100%', overflow: 'auto', paddingLeft: '16px', paddingRight: '16px' }}>
      <div className="title3 lgText prText bold" style={{ padding: '0', marginBottom: '0.5rem', textAlign: 'center' }}>
        Achievements
      </div>
      {well_done_comments?.map((comment) => (
        <div
          className="flexColumn box"
          style={{
            display: 'flex',
            flexShrink: '0',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>
            <div className="smText">{comment}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

// export function SuggestedActions({ suggested_actions }: { suggested_actions: ApiChartOutput['suggested_actions'] }) {
//   return (
//     <div style={{ width: '100%', overflow: 'auto', paddingLeft: '16px', paddingRight: '16px' }}>
//       <div className="title3 lgText prText bold" style={{ padding: '0', marginBottom: '0.5rem', textAlign: 'center' }}>
//         {suggested_actions?.category}
//       </div>
//       {suggested_actions?.actions?.map((action) => (
//         <div
//           className="flexColumn box"
//           style={{
//             display: 'flex',
//             flexShrink: '0',
//             justifyContent: 'center',
//             alignItems: 'center',
//           }}
//         >
//           <div>
//             <div className="title3 smText bold" style={{ padding: '0', marginBottom: '0.5rem' }}>
//               💡 {action.action}
//             </div>
//             <div className="smText">{action.description}</div>
//           </div>
//         </div>
//       ))}
//     </div>
//   )
// }

export function FixedRecommendations({ userType, data }: { userType: string; data: any }) {
  let recommendations
  if (userType == 'business') {
    recommendations = [
      'Choosing train travel for longer distances can result in a 30% reduction in emissions per mile compared to flying.',
      'Prioritising online meetings over in-person meetings can drastically reduce your emissions.',
      'Check out business travel platforms! These platforms manage your corporate travel and automate offsetting, reducing your carbon footprint effortlessly.',
    ]
  } else if (userType == 'consumer') {
    let carEmissions = data?.groups?.[4]?.result?.emissions
    let amount = carEmissions * 0.01
    let ruondedAmount = Math.round(amount * 100) / 100

    recommendations = [
      `Always check that your car tyres are inflated correctly. A 5 PSI increase could reduce your emissions by 12.8 kgs of CO2e`,
      "Reducing engine idling whilst driving won't just lower your carbon footprint, it can also lower your fuel costs by 10% or more.",
      <>
        Check out the{' '}
        <a href="https://www.gov.uk/green-deal-energy-saving-measures" target="_blank">
          Green Deal
        </a>
        ! Setup by the government it can help you determine what the best energy-saving improvements for your home are
        and find the optimal financing options.
      </>,
    ]
  }

  return (
    <div style={{ width: '100%', overflow: 'auto', paddingLeft: '16px', paddingRight: '16px' }}>
      <div className="title3 lgText prText bold" style={{ padding: '0', marginBottom: '0.5rem', textAlign: 'center' }}>
        Recommendations
      </div>
      {recommendations?.map((recommendation) => (
        <div
          className="flexColumn box"
          style={{
            display: 'flex',
            flexShrink: '0',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>
            <div className="smText">{recommendation}</div>
          </div>
        </div>
      ))}
    </div>
  )
}
