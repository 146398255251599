import { useCallback, useEffect, useRef, useState } from 'react'
import { getAccessToken, getReportSession } from '../api/reportAuth'
import { useAuthenticator } from '@aws-amplify/ui-react'
import virtueBankLogo from '../images/virtueBank.png'
import virtueInsuranceLogo from '../images/virtueInsurance.png'
import virtueAccountingLogo from '../images/virtueAccounting.png'
import '../index.css'

const REPORT_URL = process.env.REACT_APP_REPORT_URL ?? ''

interface UsecaseConfig {
  [key: string]: string
}

const usecasesConfig: UsecaseConfig = {
  Bank: virtueBankLogo,
  Insurance: virtueInsuranceLogo,
  Accounting: virtueAccountingLogo,
}

export default function Report() {
  const selectedUsecase: string = localStorage.getItem('demoType') || 'Bank'
  const logoUrl = localStorage.getItem('logo') || usecasesConfig[selectedUsecase]

  const { user } = useAuthenticator((context) => [context.user])
  const [sessionId, setSessionId] = useState<string>('')
  const routeRef = useRef('')

  const handleUsecaseChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    localStorage.setItem('demoType', event.target.value)
    window.location.reload()
  }

  const getSession = useCallback(() => {
    if (user?.username) {
      getAccessToken()
        .then((token) => {
          getReportSession(token.access_token, user.username!)
            .then((session) => {
              setSessionId(session.sessionId)
            })
            .catch((err) => {
              console.error('Error getting the report session. Reason: ' + err)
            })
        })
        .catch((err) => {
          console.error('Error getting the access token. Reason: ' + err)
        })
    }
  }, [user])

  // Refresh session every hour
  useEffect(() => {
    getSession()
    const interval = setInterval(() => {
      getSession()
    }, 60 * 60 * 1000)

    return () => clearInterval(interval)
  }, [getSession])

  // Listen to iframe event
  useEffect(() => {
    const handleMessage = (event: any) => {
      const { type, payload } = event.data
      if (type === 'ROUTE_CHANGE_EVENT') {
        routeRef.current = payload.route
      }
    }
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])
  return (
    <div>
      <div className="headerReport">
        <div className="logoReportContainer" id="logoReport">
          <img src={logoUrl} alt="logo" className="logoReport" />
          <img
            src="https://report.connect.earth/img/poweredByConnectEarth.png"
            alt="poweredBy"
            className="poweredByReport"
          />
          <div className="dropdownContainerReport">
            <select onChange={handleUsecaseChange} value={selectedUsecase} className="usecaseDropdownReport">
              <option value="Bank">Bank</option>
              <option value="Insurance">Insurance</option>
              <option value="Accounting">Accounting</option>
            </select>
          </div>
        </div>
      </div>
      <iframe
        id="reportIframe"
        className="connectReport"
        src={`${REPORT_URL}?sessionId=${sessionId}`}
        title="Connect Report"
      ></iframe>
    </div>
  )
}
