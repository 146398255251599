import axios from 'axios'

export function uploadBatchTransactions(userId: string, data: string[]): Promise<any[]> {
  const batchSize = 500
  const promises: Promise<any>[] = []

  for (let i = 0; i < data.length; i += batchSize) {
    const batch = data.slice(i, i + batchSize)

    promises.push(
      new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: process.env.REACT_APP_CONNECT_API_URL + '/transaction/bulk/upload',
          headers: {
            'x-api-key': process.env.REACT_APP_CONNECT_API_KEY,
          },
          data: {
            geo: 'BE',
            userId: userId,
            emissionsFactorsVersion: localStorage.getItem('emissions_factor_version') || 'v1_1',
            currencyISO: localStorage.getItem('currency') || 'GBP',
            transactions: batch,
          },
        })
          .then((res: any) => {
            resolve(res.data)
          })
          .catch((err: any) => {
            reject(`Error uploading transactions to the API (${err})`)
          })
      }),
    )
  }

  return Promise.all(promises)
}
