import { currenciesList } from '../helpers/currencyList'

export default function AccountBalance() {
  return (
    <div className="flexColumn box" style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <div>
        <div className="bold smText">{localStorage.getItem('userType') === 'business' ? 'Acme Ltd' : 'SMITH JC'}</div>
        <div className="dgText smText">8233 - 1634 4234 1283</div>
      </div>

      <div className="child bold smText">
        +{currenciesList[localStorage.getItem('currency') || 'GBP'].symbol}
        36,968.50
      </div>
    </div>
  )
}
