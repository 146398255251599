import { Chart } from 'react-google-charts'

export const data = [
  ['Date', 'Submissions'],
  ['Apr 27, 2022', 51],
  ['Apr 28, 2022', 67],
  ['Apr 29, 2022', 332],
  ['May 2, 2022', 340],
  ['May 3, 2022', 373],
  ['May 4, 2022', 385],
  ['May 5, 2022', 395],
  ['May 6, 2022', 421],
  ['May 9, 2022', 439],
  ['May 10, 2022', 457],
  ['May 11, 2022', 469],
  ['May 12, 2022', 489],
  ['May 13, 2022', 499],
  ['May 16, 2022', 509],
  ['May 17, 2022', 519],
  ['May 18, 2022', 546],
  ['May 19, 2022', 627],
  ['May 20, 2022', 651],
  ['May 23, 2022', 675],
  ['May 24, 2022', 692],
  ['May 25, 2022', 716],
  ['May 26, 2022', 752],
  ['May 27, 2022', 779],
  ['May 30, 2022', 803],
  ['May 31, 2022', 834],
  ['Jun 1, 2022', 860],
  ['Jun 6, 2022', 874],
  ['Jun 7, 2022', 900],
  ['Jun 8, 2022', 920],
  ['Jun 9, 2022', 945],
  ['Jun 10, 2022', 966],
  ['Jun 13, 2022', 993],
  ['Jun 14, 2022', 1010],
  ['Jun 15, 2022', 1016],
  ['Jun 17, 2022', 1019],
  ['Jun 20, 2022', 1031],
  ['Jun 21, 2022', 1048],
  ['Jun 22, 2022', 1068],
  ['Jun 23, 2022', 1100],
  ['Jun 24, 2022', 1132],
  ['Jun 25, 2022', 1142],
  ['Jun 27, 2022', 1171],
  ['Jun 28, 2022', 1189],
  ['Jun 29, 2022', 1199],
  ['Jul 4, 2022', 1209],
  ['Jul 5, 2022', 1216],
  ['Jul 6, 2022', 1224],
  ['Jul 7, 2022', 1231],
  ['Jul 8, 2022', 1240],
  ['Jul 11, 2022', 1247],
  ['Jul 12, 2022', 1259],
  ['Jul 13, 2022', 1271],
  ['Jul 14, 2022', 1273],
  ['Jul 15, 2022', 1277],
  ['Jul 18, 2022', 1291],
  ['Jul 19, 2022', 1300],
  ['Jul 20, 2022', 1314],
  ['Jul 21, 2022', 1333],
  ['Jul 25, 2022', 1352],
  ['Jul 26, 2022', 1359],
  ['Jul 27, 2022', 1369],
  ['Jul 28, 2022', 1373],
  ['Jul 29, 2022', 1374],
  ['Aug 1, 2022', 1381],
  ['Aug 2, 2022', 1390],
  ['Aug 3, 2022', 1405],
  ['Aug 4, 2022', 1422],
  ['Aug 5, 2022', 1430],
  ['Aug 10, 2022', 1917],
  ['Aug 12, 2022', 1918],
  ['Aug 16, 2022', 1929],
  ['Aug 17, 2022', 1945],
  ['Aug 22, 2022', 1950],
  ['Aug 23, 2022', 1954],
  ['Aug 24, 2022', 1970],
  ['Aug 25, 2022', 1976],
  ['Aug 26, 2022', 2004],
  ['Aug 30, 2022', 2016],
  ['Aug 31, 2022', 2047],
  ['Sep 1, 2022', 2060],
  ['Sep 2, 2022', 2077],
  ['Sep 5, 2022', 2101],
  ['Sep 6, 2022', 2119],
  ['Sep 7, 2022', 2138],
  ['Sep 12, 2022', 2196],
  ['Sep 13, 2022', 2219],
  ['Sep 15, 2022', 2233],
  ['Sep 16, 2022', 2265],
  ['Sep 20, 2022', 2279],
  ['Sep 21, 2022', 2292],
  ['Sep 22, 2022', 2312],
  ['Sep 23, 2022', 2342],
  ['Sep 26, 2022', 2363],
  ['Sep 27, 2022', 2380],
  ['Sep 28, 2022', 2402],
  ['Sep 29, 2022', 2435],
  ['Sep 30, 2022', 2448],
  ['Oct 3, 2022', 2484],
  ['Oct 4, 2022', 2502],
  ['Oct 5, 2022', 2508],
  ['Oct 13, 2022', 2509],
  ['Oct 14, 2022', 2512],
  ['Oct 17, 2022', 2536],
  ['Oct 18, 2022', 2555],
  ['Oct 19, 2022', 2587],
  ['Oct 20, 2022', 2609],
  ['Oct 21, 2022', 2616],
  ['Oct 24, 2022', 2622],
  ['Oct 25, 2022', 2623],
  ['Oct 27, 2022', 2632],
  ['Oct 31, 2022', 2651],
  ['Nov 1, 2022', 2669],
  ['Nov 2, 2022', 2687],
  ['Nov 3, 2022', 2692],
  ['Nov 4, 2022', 2710],
  ['Nov 7, 2022', 2720],
  ['Nov 8, 2022', 2721],
  ['Nov 21, 2022', 2724],
]

export const options = {
  title: 'Submissions',
  curveType: 'function',
  legend: { position: 'bottom' },
  colors: ['#5bb030'],
}

export function LineChart() {
  return <Chart chartType="LineChart" width="100%" height="350px" data={data} options={options} />
}
