import { ApiBatchChartTransactionInput, ApiChartOutput } from './../interface/chartsInterface'
import axios from 'axios'
import { Transaction } from '../interface/transactionInterface'

export function getBatchChartData(data: Transaction[], groupSelector: string): Promise<ApiChartOutput> {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_CONNECT_API_URL + '/transaction/bulk/upload/chart',
      headers: {
        'x-api-key': process.env.REACT_APP_CONNECT_API_KEY,
      },
      data: {
        emissionsFactorsVersion: localStorage.getItem('emissions_factor_version') || 'v1_1',
        transactions: mapTransactions(data, groupSelector),
      },
    })
      .then((res: any) => {
        resolve(res.data)
      })
      .catch((err: any) => {
        reject(`Error getting estimates from the API (${err})`)
      })
  })
}

function mapTransactions(data: Transaction[], groupSelector: string): ApiBatchChartTransactionInput[] {
  const transactions: ApiBatchChartTransactionInput[] = []
  data.forEach((transaction: Transaction) => {
    if (transaction.category) {
      transactions.push({
        transactionId: transaction.id,
        group: groupSelector === 'Category' ? transaction.category.name : transaction.merchant,
      })
    }
  })
  return transactions
}
