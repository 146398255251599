import { faLeaf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { categories } from '../helpers/categoryMapping'
import { Group } from '../interface/chartsInterface'

export default function BreakdownDetail({ groups }: { groups: Group[] }) {
  if (groups) {
    sort(groups)
    const totalEmissions = groups
      .map((group: Group) => group.result.emissions)
      .reduce((a: number, b: number) => {
        return a + b
      })
    return (
      <div>
        {groups.map((group: Group) => {
          return (
            <div className="breakdownRow" key={group.group}>
              <div
                className="icon roundedIcon"
                style={{
                  backgroundColor:
                    group.iconType === 'faIcon'
                      ? categories[group.group]?.color || group.category.color
                      : 'transparent',
                }}
              >
                {group.icon}
              </div>
              <div className="name">{group.group}</div>
              <div className="fraction">{Math.round((group.result.emissions / totalEmissions) * 1000) / 10 + ' %'}</div>
              <div className="count smText">{group.result.count} transactions</div>
              <div className="emissions bold smText">
                <FontAwesomeIcon icon={faLeaf} color="var(--leaf-color)" size="xs" />
                {'  '}
                {Math.round(group.result.emissions).toLocaleString()}
                <span className="xsText">{'  '}KG CO2</span>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  return null
}

function sort(groups: Group[]) {
  groups.sort((a, b) => {
    if (a.result.emissions < b.result.emissions) {
      return 1
    }
    if (a.result.emissions > b.result.emissions) {
      return -1
    }
    return 0
  })
}
