import { Doughnut } from 'react-chartjs-2'
import { Chart, ArcElement, Legend, Tooltip } from 'chart.js'
import { Group, Result } from '../interface/chartsInterface'
import { categories } from '../helpers/categoryMapping'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'
import { chartColors } from '../helpers/chartColors'
Chart.register(ArcElement, Legend, Tooltip)

export default function BreakdownChart({
  data,
  level,
  periodDisplay,
}: {
  data: Group[]
  level: string
  periodDisplay: string
}) {
  if (data) {
    const totalEmissions = data.map((group: Group) => group.result.emissions).reduce((a: number, b: number) => a + b)
    return (
      <div className="chartContainer">
        <Doughnut
          id="breakdownChart"
          height="200px"
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
                position: 'right',
                labels: {
                  usePointStyle: true,
                },
              },
            },
          }}
          data={{
            labels: data.map((row: { group: string; result: Result }) => {
              return row.group
            }),
            datasets: [
              {
                data: data.map((row: { group: string; result: Result }) => {
                  return row.result.emissions
                }),
                backgroundColor:
                  level === 'Category'
                    ? data.map((row: { group: string; result: Result }) => {
                        return categories[row.group]?.color
                      })
                    : chartColors,
                hoverOffset: 4,
              },
            ],
          }}
        />
        <div className="flexColumn middleLabel">
          <FontAwesomeIcon icon={faLeaf} color="var(--leaf-color)" size="2x" />
          <div className="dgText smText">{Math.round(totalEmissions)} KG CO2</div>
          <div className="mGray xsText">{periodDisplay}</div>
        </div>
      </div>
    )
  }
  return null
}
