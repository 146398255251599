import { faFilter, faHome, faLeaf, faQuestion } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getEstimates } from '../api/bulkRequest'
import { useEffect, useState } from 'react'
import { ApiBatchTransactionResult, ApiBulkTransactionOutput, Transaction } from '../interface/transactionInterface'
import { loadCSV } from '../helpers/loadCSV'
import TransactionDetail from '../components/TransactionDetail'
import { categories, mccToCategory } from '../helpers/categoryMapping'
import axios from 'axios'
import { currenciesList } from '../helpers/currencyList'
import MobileFrame from '../components/MobileFrame'
import DateNavigationBar from '../components/DateNavigationBar'
import moment from 'moment'
import { getBatchEstimates } from '../api/batchTransactionRequest'
import AccountBalance from '../components/AccountBalance'
import Carousel from '../components/Carousel'

const Home = () => {
  const [csvData, setCsvData] = useState<Transaction[]>([])
  const [data, setData] = useState<Transaction[]>([])
  const [showPopup, setShowPopup] = useState(false)
  const [popupData, setPopupData] = useState<Transaction>({} as Transaction)
  const [showDateNavigation, setShowDateNavigation] = useState<boolean>(false)
  const [showInformation, setShowInformation] = useState<boolean>(false)
  const [period, setPeriod] = useState<any>({
    start: moment().startOf('month'),
    end: moment().endOf('month'),
    display: moment().format('MMMM YYYY'),
  })

  const openPopup = (transaction: Transaction) => {
    setShowPopup(true)
    setPopupData(transaction)
  }

  const closePopup = () => {
    setShowPopup(false)
  }

  const informationButtonDisplayText = 'How do our calculations work?'

  useEffect(() => {
    loadCSV()
      .then((transactions: Transaction[]) => {
        setCsvData(transactions)
      })
      .catch((err: any) => {
        console.error(err)
      })
  }, [])

  useEffect(() => {
    const primaryColor = localStorage.getItem('primary-color')
    document.documentElement.style.setProperty('--primary-color', primaryColor)
    const transactions = csvData.filter((transaction: Transaction) =>
      moment(transaction.date).isBetween(period.start, period.end, undefined, '[]'),
    )
    if (transactions.length) {
      localStorage.getItem('dataSource') === 'batchUpload'
        ? getBatchEstimates(transactions.map((transaction: Transaction) => transaction.id)).then(
            async (estimates: ApiBatchTransactionResult[]) => {
              const data = addBatchEstimates(transactions, estimates)
              await addCategoryAndIcon(data)
              sort(data)
              setData(data)
            },
          )
        : getEstimates(transactions).then(async (estimates: ApiBulkTransactionOutput[]) => {
            const data = addEstimates(transactions, estimates)
            await addCategoryAndIcon(data)
            setData(data)
          })
    } else {
      setData([])
    }
  }, [csvData, period])

  return (
    <MobileFrame
      content={
        <>
          {showPopup && <TransactionDetail closePopup={closePopup} transaction={popupData} />}
          <div className="viewHeader">
            <div className="title1 bold">
              <div>
                {' '}
                <FontAwesomeIcon icon={faHome} className="mRight1" />
                HOME
              </div>
              <FontAwesomeIcon
                className="clickable"
                icon={faFilter}
                onClick={() => setShowDateNavigation((currentValue) => !currentValue)}
              />
            </div>
            {showDateNavigation && <DateNavigationBar setPeriod={setPeriod} isInsights={false} />}
            <AccountBalance />
            <div
              className="moreInfoButton smText"
              onClick={() => {
                setShowInformation(true)
              }}
            >
              {informationButtonDisplayText}
            </div>
            {showInformation && <Carousel showInformation={setShowInformation} />}
            <div className="title2 smText">TRANSACTIONS</div>
          </div>
          <div className="flexColumn scrollable">
            {data.length === 0 && <div className="padH1 mTop1 dgText smText">No transactions for this period.</div>}
            {data.map((transaction: Transaction) => {
              if (transaction.description) {
                return (
                  <div key={transaction.id} className="transaction" onClick={() => openPopup(transaction)}>
                    <div
                      className="icon roundedIcon"
                      style={{
                        backgroundColor:
                          transaction.iconType === 'faIcon' ? transaction.category?.color ?? 'black' : 'transparent',
                      }}
                    >
                      {transaction.icon}
                    </div>
                    <div className="description">{transaction.description}</div>
                    <div className="date smText">{new Date(transaction.date).toLocaleDateString()}</div>
                    <div className="amount">
                      {+transaction.amount > 0 ? '+' : '-'}
                      {currenciesList[localStorage.getItem('currency') || 'GBP'].symbol}
                      {Math.abs(+transaction.amount).toFixed(2)}
                    </div>
                    <div className="emissions bold smText">
                      <FontAwesomeIcon icon={faLeaf} color="var(--leaf-color)" size="xs" />
                      {'  '}
                      {transaction.kgCo2 ?? '-'} <span className="xsText">KG CO2</span>
                    </div>
                  </div>
                )
              }
              return null
            })}
          </div>
        </>
      }
    ></MobileFrame>
  )
}

async function addCategoryAndIcon(rawTransactions: Transaction[]) {
  const promises: Promise<void>[] = []
  rawTransactions.forEach((transaction: Transaction, i) => {
    promises.push(
      new Promise<void>((resolve, reject) => {
        transaction.category = categories[mccToCategory[transaction.mcc]]
        if (!transaction.merchant) {
          addCategoryIcon(transaction)
          resolve()
        } else {
          const url = !transaction.merchantLogo
            ? 'https://logo.clearbit.com/' +
              (transaction.merchantUrl || transaction.merchant?.replace("'", '') + '.com')
            : transaction.merchantLogo

          axios
            .get(url)
            .then(() => {
              addMerchantIcon(transaction, url)
              resolve()
            })
            .catch(() => {
              addCategoryIcon(transaction)
              resolve()
            })
        }
      }),
    )
  })
  await Promise.all(promises)
}

function addEstimates(rawTransactions: Transaction[], estimates: ApiBulkTransactionOutput[]): Transaction[] {
  const transactions: Transaction[] = []
  rawTransactions.forEach((rawTransaction: Transaction, i: number) => {
    transactions.push({
      ...rawTransaction,
      kgCo2: estimates[i].value?.kg_of_CO2e_emissions,
      emissionsLevel: estimates[i].value?.emissions_level,
      similarTo: estimates[i].value?.similar_to,
    })
  })
  return transactions
}

function addBatchEstimates(rawTransactions: Transaction[], estimates: ApiBatchTransactionResult[]): Transaction[] {
  let transactions: Transaction[] = []
  rawTransactions.forEach((transaction: Transaction, i) => {
    const result = estimates.find((estimate: ApiBatchTransactionResult) => transaction.id === estimate.transactionId)
    if (result) {
      transactions.push({
        ...transaction,
        kgCo2: result.kgCO2eEmissions,
        emissionsLevel: result.emissionsLevel,
      })
    }
  })
  return transactions
}

function addCategoryIcon(transaction: Transaction) {
  transaction.icon = <FontAwesomeIcon icon={transaction.category?.icon ?? faQuestion} width="100%" color="white" />
  transaction.iconType = 'faIcon'
}

function addMerchantIcon(transaction: Transaction, url: string) {
  transaction.icon = <img src={url} alt="." />
  transaction.iconType = 'img'
}

function sort(transactions: Transaction[]) {
  transactions.sort((a, b) => {
    if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
      return 1
    }
    if (new Date(a.date).getTime() > new Date(b.date).getTime()) {
      return -1
    }
    return 0
  })
  return transactions
}

export default Home
