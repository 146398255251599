import { useAuthenticator } from '@aws-amplify/ui-react'
import { faGear } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from 'react-hook-form'
import { getAccessToken, getReportSession } from '../api/reportAuth'
import { uploadReportTransactions } from '../api/uploadReportTransactions'
import { uploadBatchTransactions } from '../api/batchTransactionUpload'
import MobileFrame from '../components/MobileFrame'
import { currenciesList } from '../helpers/currencyList'
import { exiobaseRegions } from '../helpers/exiobaseRegions'
import { loadReportTransactionsCSV } from '../helpers/loadReportTransactionsCSV'

import { countriesList, Country } from '../helpers/countriesList'
import { useState } from 'react'
import { TwitterPicker } from 'react-color'
import LogoUploader from '../components/LogoUploader'
import { loadTimeSeriesTransactionsCSV } from '../helpers/loadTimeSeriesTransactionsCSV'

export type FormSettings = {
  userType: string
  country: string
  currency: string
  dietaryHabits: string
  dataSource: string
  carHabits: string
}

export default function Settings() {
  const uploadButtonDisplayText = 'Upload Sample Transactions'
  const uploadButtonUploadingText = 'Uploading...'
  const { user } = useAuthenticator((context) => [context.user])
  const [uploadButtonText, setUploadButtonText] = useState(uploadButtonDisplayText)
  const [dashboardUserType, setDashboardUserType] = useState(
    localStorage.getItem('dashboard_user_type') || 'baseline_user',
  )
  const [emissionsFactorVersion, setEmissionsFactorVersion] = useState(
    localStorage.getItem('emissions_factor_version') || 'v1_1',
  )

  const { register, handleSubmit } = useForm<FormSettings>({
    defaultValues: {
      userType: localStorage.getItem('userType') || 'consumer',
      country: localStorage.getItem('country') || 'GB',
      currency: localStorage.getItem('currency') || 'GBP',
      dietaryHabits:
        localStorage.getItem('dietaryHabits') ||
        (localStorage.getItem('userType') === 'consumer' ? 'vegetarian' : 'na'),
      dataSource: localStorage.getItem('dataSource') || 'rtRequests',
      carHabits:
        localStorage.getItem('carHabits') || (localStorage.getItem('userType') === 'business' ? 'noCar' : 'na'),
    },
  })

  const userTypes: any = {
    consumer: 'Consumer',
    business: 'Business',
  }

  const dietaryHabits: any = {
    na: 'N/A',
    vegan: 'Vegan',
    vegetarian: 'Vegetarian',
    pescatarian: 'Pescatarian',
  }

  const carHabits: any = {
    na: 'N/A',
    noCar: 'No Car',
  }

  const dataSource: any = {
    rtRequests: 'Realtime requests',
    batchUpload: 'Batch upload',
  }

  const dashboardUserTypes: any = {
    baseline_user: 'Dashboard Baseline User',
    user_1_no_bills: 'Dashboard User 1 - No Bills',
    user_2_only_bills: 'Dashboard User 2 - Only Bills',
    user_3_missing_months: 'Dashboard User 3 - Missing Months',
    user_4_family_household: 'Dashboard User 4 - Family Household',
    user_5_more_business_expenses: 'Dashboard User 5 - More Business Expenses',
    user_6_joint_couples_account: 'Dashboard User 6 - Joint Couples Account',
    user_7_spend_on_wants: 'Dashboard User 7 - Spend on Wants',
    user_8_monthly_variation: 'Dashboard User 8 - Monthly Variation',
    user_9_higher_luxury_spend: 'Dashboard User 9 - Higher Luxury Spend',
  }

  const emissionsFactorVersions: any = {
    v1_0: 'v1_0',
    v1_1: 'v1_1',
    v1_2: 'v1_2',
  }

  function onSubmit(formData: any) {
    Object.keys(formData).forEach((key: string) => {
      localStorage.setItem(key, formData[key])
    })
  }

  async function uploadTransactions() {
    if (uploadButtonText !== uploadButtonUploadingText) {
      setUploadButtonText(uploadButtonUploadingText)
      const transactions = await loadReportTransactionsCSV()
      const { access_token } = await getAccessToken()
      const { sessionId } = await getReportSession(access_token, user.username || '')
      await uploadReportTransactions(sessionId, transactions)
      setUploadButtonText(uploadButtonDisplayText)
    }
  }

  async function handleDashboardUserTypeCategory(e: any) {
    setDashboardUserType(e.target.value)
    localStorage.setItem('dashboard_user_type', e.target.value)
    const transactions = await loadTimeSeriesTransactionsCSV(e.target.value)
    await uploadBatchTransactions(e.target.value, transactions)
  }

  async function handleEmissionFactorsVersion(e: any) {
    setEmissionsFactorVersion(e.target.value)
    localStorage.setItem('emissions_factor_version', e.target.value)
    const transactions = await loadTimeSeriesTransactionsCSV(dashboardUserType)
    await uploadBatchTransactions(dashboardUserType, transactions)
  }

  return (
    <MobileFrame
      content={
        <>
          <div className="viewHeader">
            <div className="title1 bold">
              <div>
                {' '}
                <FontAwesomeIcon icon={faGear} className="mRight1" />
                SETTINGS
              </div>
            </div>
          </div>
          <div className="flexColumn scrollable">
            <form onSubmit={handleSubmit(onSubmit)} className="padH1">
              <label className="dgText smText ucText">User type</label>
              <select {...register('userType')} className="settingsInput">
                {Object.keys(userTypes).map((userTypeKey: string) => {
                  return (
                    <option value={userTypeKey} key={userTypeKey}>
                      {userTypes[userTypeKey]}
                    </option>
                  )
                })}
              </select>
              <label className="dgText smText ucText">Country</label>
              <select {...register('country')} className="settingsInput">
                {countriesList.map((country: Country) => {
                  if (exiobaseRegions.includes(country.code)) {
                    return (
                      <option value={country.code} key={country.code}>
                        {country.name}
                      </option>
                    )
                  }
                  return null
                })}
              </select>
              <label className="dgText smText ucText">Currency</label>
              <select {...register('currency')} className="settingsInput">
                {Object.values(currenciesList).map((currency: any) => {
                  return (
                    <option value={currency.code} key={currency.code}>
                      {currency.name}
                    </option>
                  )
                })}
              </select>
              <label className="dgText smText ucText">Dietary Habits</label>
              <select {...register('dietaryHabits')} className="settingsInput">
                {Object.keys(dietaryHabits).map((dietaryKey: string) => {
                  return (
                    <option value={dietaryKey} key={dietaryKey}>
                      {dietaryHabits[dietaryKey]}
                    </option>
                  )
                })}
              </select>
              <label className="dgText smText ucText">Car Habits</label>
              <select {...register('carHabits')} className="settingsInput">
                {Object.keys(carHabits).map((carKey: string) => {
                  return (
                    <option value={carKey} key={carKey}>
                      {carHabits[carKey]}
                    </option>
                  )
                })}
              </select>
              <label className="dgText smText ucText">Data source</label>
              <select {...register('dataSource')} className="settingsInput">
                {Object.keys(dataSource).map((sourceKey: string) => {
                  return (
                    <option value={sourceKey} key={sourceKey}>
                      {dataSource[sourceKey]}
                    </option>
                  )
                })}
              </select>

              <div className="flexRow mTop1">
                <button className="primaryButton" type="submit">
                  Save
                </button>
              </div>
            </form>
            <div className="userTypeContainer">
              <label className="dgText smText ucText">Dashboard User Type</label>
              <select
                className="settingsInput"
                value={dashboardUserType || 'baseline_user'}
                onChange={handleDashboardUserTypeCategory}
              >
                {Object.keys(dashboardUserTypes).map((userKey: string) => {
                  return (
                    <option value={userKey} key={userKey}>
                      {dashboardUserTypes[userKey]}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className="userTypeContainer">
              <label className="dgText smText ucText">Emissions Factors Version</label>
              <select
                className="settingsInput"
                value={emissionsFactorVersion || 'v1_1'}
                onChange={handleEmissionFactorsVersion}
              >
                {Object.keys(emissionsFactorVersions).map((version: string) => {
                  return (
                    <option value={version} key={version}>
                      {emissionsFactorVersions[version]}
                    </option>
                  )
                })}
              </select>
            </div>

            <div>
              <div className="title2 ucText mTop1 smText">Colors</div>
              <TwitterPicker
                width="100%"
                onChangeComplete={(color: any) => {
                  document.documentElement.style.setProperty('--primary-color', color.hex)
                  localStorage.setItem('primary-color', color.hex)
                }}
              />
            </div>
            <div>
              <div className="title2 ucText mTop1 smText">Logo</div>
              <LogoUploader />
            </div>
            <div>
              <div className="title2 ucText mTop1 smText">Connect Report</div>
              <div className="secondaryButton smText" onClick={() => uploadTransactions()}>
                {uploadButtonText}
              </div>
            </div>
          </div>
        </>
      }
    ></MobileFrame>
  )
}
