import awsExports from '../aws-exports'
import logo from '../images/logo.png'
import { Amplify } from 'aws-amplify'
import { Authenticator, useTheme, View } from '@aws-amplify/ui-react'
import { Navigate } from 'react-router-dom'
import '@aws-amplify/ui-react/styles.css'
Amplify.configure(awsExports)

const Login = () => {
  const components = {
    Header() {
      const { tokens } = useTheme()
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <img src={logo} alt="Connect Earth" className="logo" />
        </View>
      )
    },
  }

  return (
    <div>
      <Authenticator loginMechanisms={['email']} components={components}>
        {() => <Navigate to="/" replace={true} />}
      </Authenticator>
    </div>
  )
}

export default Login
