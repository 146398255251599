import Papa from 'papaparse'
import { getUrl } from 'aws-amplify/storage'
import { Transaction } from '../interface/transactionInterface'

export function loadCSV(): Promise<Transaction[]> {
  return new Promise((resolve, reject) => {
    getUrl({
      key: localStorage.getItem('userType') === 'business' ? 'businessTransactions.csv' : 'transactions3.csv',
    })
      .then((result: any) => {
        Papa.parse(result.url.href, {
          header: true,
          download: true,
          complete: function (results: any) {
            // TODO: uncomment when we want to use the dates in the CSV
            // if (!results.meta.fields.includes('date')) {
            addRandomDates(results.data)
            // }
            resolve(results.data)
          },
        })
      })
      .catch((err: string) => {
        reject(`Error loading the CSV data (${err})`)
      })
  })
}

function addRandomDates(data: Transaction[]) {
  let date = new Date().getTime()
  const oneDay = 86400000
  // Number of extra days we need considering the current date (e.g if day 1, we'll generate 30 extra transactions for day 1)
  let missingDays = 31 - new Date(date).getDate()
  data.forEach((el: Transaction, i: number) => {
    if (el['date']) {
      el['date'] = new Date(new Date(el['date']).getTime() + i * 1000).toISOString()
    } else {
      if (new Date(date).getDate() === 1 && new Date(date).getMonth() === new Date().getMonth()) {
        if (missingDays === 0) {
          date = date - oneDay
        } else {
          missingDays -= 1
        }
      } else {
        date = date - oneDay
      }
      el['date'] = new Date(date).toISOString()
    }
  })
}
