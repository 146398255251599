import { useAuthenticator } from '@aws-amplify/ui-react'
import { faLeaf, faTree } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { getBatchEstimates } from '../api/batchTransactionRequest'
import { getEstimates } from '../api/bulkRequest'
import { getOffsets, getSessionId } from '../api/offsetsRequest'
import AccountBalance from '../components/AccountBalance'
import MobileFrame from '../components/MobileFrame'
import { currenciesList } from '../helpers/currencyList'
import { loadCSV } from '../helpers/loadCSV'
import { Offset } from '../interface/offsetInterface'
import { Transaction, ApiBulkTransactionOutput, ApiBatchTransactionResult } from '../interface/transactionInterface'

export default function Offsets() {
  const { user } = useAuthenticator((context) => [context.user])
  const [offsets, setOffsets] = useState<any>({ breakdown: [], total: 0 })
  const [totalEmissions, setTotalEmissions] = useState<number>(0)
  useEffect(() => {
    getOffsets(user?.username || 'anon').then((offsets: Offset[]) => {
      setOffsets({
        breakdown: sort(offsets),
        total: offsets.map((offset: Offset) => offset.amount / 1000).reduce((a: number, b: number) => a + b),
      })
    })
  }, [user])

  useEffect(() => {
    loadCSV()
      .then((transactions: Transaction[]) => {
        localStorage.getItem('dataSource') === 'batchUpload'
          ? getBatchEstimates(transactions.map((transaction) => transaction.id)).then(
              (estimates: ApiBatchTransactionResult[]) => {
                const total = estimates
                  .map((estimate: ApiBatchTransactionResult) => estimate.kgCO2eEmissions || 0)
                  .reduce((a: number, b: number) => a + b)
                setTotalEmissions(Math.round(total))
              },
            )
          : getEstimates(transactions).then((estimates: ApiBulkTransactionOutput[]) => {
              const total = estimates
                .map((estimate: ApiBulkTransactionOutput) => estimate.value?.kg_of_CO2e_emissions || 0)
                .reduce((a: number, b: number) => a + b)
              setTotalEmissions(Math.round(total))
            })
      })
      .catch((err: any) => {
        console.error(err)
      })
  }, [])

  function buyOffset() {
    getSessionId(user?.username || 'anon').then((sessionId: string) => {
      window.location.href =
        process.env.REACT_APP_CONNECT_API_OFFSETS_URL +
        '/offset/checkout?sessionId=' +
        sessionId +
        '&goBackUrl=' +
        window.location.href
    })
  }

  return (
    <MobileFrame
      content={
        <>
          <div className="viewHeader">
            <div className="title1 bold">
              <div>
                {' '}
                <FontAwesomeIcon icon={faTree} className="mRight1" />
                REMOVALS
              </div>
            </div>
            <AccountBalance />
            <div className="box">
              <div className="offsetOverview mBottom1">
                <div className="flexColumn offsetOverviewItem">
                  <div className="xsText ucText">
                    Total
                    <br />
                    footprint
                  </div>
                  <div className="xlText dgText bold">{totalEmissions.toLocaleString()}</div>
                  <div className="xsText mGray ucText">kg CO2</div>
                </div>
                <div className="flexColumn offsetOverviewItem">
                  <div className="xsText ucText">
                    Amount
                    <br />
                    removed
                  </div>
                  <div className="xlText dgText bold">{offsets.total.toLocaleString()}</div>
                  <div className="xsText mGray ucText">kg CO2</div>
                </div>
                <div className="flexColumn offsetOverviewItem">
                  <div className="xsText ucText">
                    Remaining
                    <br />
                    footprint
                  </div>
                  <div className="xlText prText bold">{(totalEmissions - offsets.total).toLocaleString()}</div>
                  <div className="xsText mGray ucText">kg CO2</div>
                </div>
              </div>
              <div className="blockButton mTop1 clickable" onClick={buyOffset}>
                Reduce with removals
              </div>
            </div>
            <div className="title2 smText dgText ucText">Your Removals</div>
          </div>

          <div className="scrollable">
            {offsets.breakdown.map((offset: Offset) => {
              return (
                <div className="offsetItem" key={offset.orderId}>
                  <div className="amount emissions">
                    <div className="bold">
                      <FontAwesomeIcon icon={faLeaf} color="var(--leaf-color)" size="xs" />
                      {'  '}
                      {(offset.amount / 1000).toLocaleString()}
                    </div>
                  </div>
                  <div className="amountUnits emissions ucText xsText">kg CO2</div>
                  <div className="price dgText smText">
                    {currenciesList[offset.currency].symbol + (offset.price / 100).toFixed(2)}
                  </div>
                  <div className="project">{offset.projectName}</div>
                  <div className="date smText mGray">{new Date(offset.createDate).toLocaleDateString()}</div>
                  <div className="state xsText">{offset.state}</div>
                  <a className="viewCert xsText" href={offset.certificateLink} target="blank">
                    View Certificate
                  </a>
                </div>
              )
            })}
          </div>
        </>
      }
    />
  )
}

function sort(transactions: Offset[]) {
  transactions.sort((a, b) => {
    if (a.createDate < b.createDate) {
      return 1
    }
    if (a.createDate > b.createDate) {
      return -1
    }
    return 0
  })
  return transactions
}
