export const exiobaseRegions = [
  'BG',
  'US',
  'GB',
  'AT',
  'AU',
  'BE',
  'BR',
  'CA',
  'CH',
  'CN',
  'CZ',
  'DE',
  'DK',
  'ES',
  'FR',
  'GR',
  'HR',
  'HU',
  'IT',
  'JP',
  'KR',
  'LT',
  'LV',
  'MX',
  'NL',
  'NO',
  'PL',
  'PT',
  'RU',
  'SK',
  'TW',
  'EE',
  'ID',
  'IE',
  'MT',
  'SE',
  'SI',
  'ZA',
]
