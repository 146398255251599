import { Dispatch, SetStateAction, useState } from 'react'
import infoScreenData from '../fixtures/information_screen_data.json'
import { faAward, faCalculator, faEarthAmericas, faLeaf, faTag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactComponent as ExitButton } from '../images/exit_button.svg'
import { ReactComponent as EmissionsEquation } from '../images/emissions_equation.svg'
import { ReactComponent as FAQsCta } from '../images/faqs_cta.svg'
import { ReactComponent as BankHomepageCta } from '../images/bank_homepage_cta.svg'

export default function Carousel({ showInformation }: { showInformation: Dispatch<SetStateAction<boolean>> }) {
  const [screenIndex, setScreenIndex] = useState(0)

  return (
    <>
      <div className="carousel">
        <div className="carouselContainer">
          <div className="carouselContent">
            <div className="carouselTopContent">
              <div className="exitButton">
                <div className="exitButtonText" onClick={() => showInformation(false)}>
                  <ExitButton />
                </div>
              </div>
              <div className="carouselImage">{selectImage(infoScreenData[screenIndex].image)}</div>
              <div className="carouselTitle">{infoScreenData[screenIndex].title}</div>
            </div>
            <div className="carouselDescription">
              {infoScreenData[screenIndex].description.split('. ').map((sentence, index) => {
                const splitSentence = sentence.split(/<|>/)
                return (
                  <>
                    <p className="carouselText smText">
                      {splitSentence[0]}
                      <b>{splitSentence[1]}</b>
                    </p>
                    {index === 0 && screenIndex === 1 && (
                      <div>
                        <EmissionsEquation />
                      </div>
                    )}
                    {index === 0 && screenIndex === 4 && (
                      <div>
                        <FAQsCta />
                      </div>
                    )}
                    {index === 1 && screenIndex === 4 && (
                      <div>
                        <BankHomepageCta />
                      </div>
                    )}
                  </>
                )
              })}
            </div>
            <div className="carouselNavigation">
              <div
                className={screenIndex > 0 ? 'previousButton' : 'previousButton hideButton'}
                onClick={() => {
                  setScreenIndex(screenIndex - 1)
                }}
              >
                {'<'}
              </div>
              <div>
                {infoScreenData.map((_, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        screenIndex === index
                          ? 'carouselNavigationButton selectedNavButton'
                          : 'carouselNavigationButton'
                      }
                      onClick={() => setScreenIndex(index)}
                    />
                  )
                })}
              </div>
              <div
                className={screenIndex < infoScreenData.length - 1 ? 'nextButton' : 'nextButton hideButton'}
                onClick={() => {
                  setScreenIndex(screenIndex + 1)
                }}
              >
                {'>'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const selectImage = (imageName: string) => {
  switch (imageName) {
    case 'leaf_question_mark':
      return <FontAwesomeIcon className="infoIcon" icon={faLeaf} />
    case 'calculation':
      return <FontAwesomeIcon className="infoIcon" icon={faCalculator} />
    case 'tags':
      return <FontAwesomeIcon className="infoIcon" icon={faTag} />
    case 'footprint':
      return <FontAwesomeIcon className="infoIcon" icon={faEarthAmericas} />
    case 'questions':
      return <FontAwesomeIcon className="infoIcon" icon={faAward} />
  }
}
