import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

function LogoUploader() {
  const [selectedImage, setSelectedImage] = useState(null)

  const handleImageChange = async (event: any) => {
    const file = event.target.files[0]
    setSelectedImage(file)
  }

  const save = async () => {
    const base64Image = await toBase64(selectedImage)
    localStorage.setItem('logo', base64Image)
    window.location.reload()
  }

  const deleteLogo = () => {
    localStorage.setItem('logo', '')
    window.location.reload()
  }

  return (
    <div>
      <form className="flexRow">
        <input type="file" id="logo-input" onChange={handleImageChange} style={{ display: 'none' }} />
        <label htmlFor="logo-input" className="secondaryButton">
          Choose File
        </label>
        {selectedImage && (
          <div className="secondaryButton" onClick={save}>
            {' '}
            Save Logo
          </div>
        )}
      </form>
      {selectedImage && (
        <div>
          <div className="title2">Selected Image:</div>
          <img className="logoImg" src={URL.createObjectURL(selectedImage)} alt="Selected" />
        </div>
      )}
      {localStorage.getItem('logo') && (
        <div>
          <div className="title2">
            <div>Stored Image:</div>{' '}
            <FontAwesomeIcon className="clickable" icon={faTrash} onClick={() => localStorage.setItem('logo', '')} />
          </div>
          <img className="logoImg" src={localStorage.getItem('logo') || undefined} alt="Stored" />
        </div>
      )}
    </div>
  )

  function toBase64(file: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
      reader.readAsDataURL(file)
    })
  }
}

export default LogoUploader
