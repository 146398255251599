import axios from 'axios'

export function post(endpoint: string, data: any) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_CONNECT_API_URL + endpoint,
      headers: {
        'x-api-key': process.env.REACT_APP_CONNECT_API_KEY,
      },
      data,
    })
      .then((res: any) => {
        resolve(res.data)
      })
      .catch((err: any) => {
        reject(`Error calling the API (${err})`)
      })
  })
}
