import moment from 'moment'
import { Bar } from 'react-chartjs-2'
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { useEffect, useRef, useState } from 'react'
import FootprintStats from './FootprintStats'
import { getTimeSeriesChartData } from '../api/timeseriesChartRequest'
import { TimeSeriesChartOutput } from '../interface/chartsInterface'
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default function DashboardChart({ dateFilter, datePeriod }: { dateFilter: string; datePeriod: any }) {
  const chartRef = useRef() as any
  const [userData, setUserData] = useState() as any
  const [chartDataValue, setChartDataValue] = useState() as any[]

  useEffect(() => {
    const response = getTimeSeriesChartData(datePeriod)
    response.then((data: TimeSeriesChartOutput) => {
      setUserData(data)
    })
  }, [])

  useEffect(() => {
    const response = getTimeSeriesChartData(dateFilter === 'Month' ? datePeriod : `${moment(datePeriod).year()}-12-31`)
    response.then((data: TimeSeriesChartOutput) => {
      setUserData(data)
      setChartDataValue(
        data?.monthly_breakdown.find((item: any) => item.month === `${moment(datePeriod).format('YYYY-MM')}`)
          ?.kg_co2e ?? null,
      )
    })
  }, [datePeriod, dateFilter])

  const labels = userData?.monthly_breakdown?.map((months: any) => moment(months.month).format('MMM'))

  const data = {
    labels,
    datasets: [
      {
        label: 'CO2 Emissions (kg)',
        data: labels?.map((_: any, index: number) => userData?.monthly_breakdown[index].kg_co2e),
        backgroundColor: (ctx: any) => {
          if (dateFilter !== 'Year') {
            if (moment(datePeriod).month() === ctx.dataIndex) {
              return localStorage.getItem('primary-color') || '#5bb030'
            } else {
              return '#808080'
            }
          } else {
            return '#808080'
          }
        },
      },
    ],
  }

  const options = {
    events:
      dateFilter === 'Yearly'
        ? ([] as any)
        : (['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove', 'touchend'] as any),
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        ticks: {
          display: true,
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
      y: {
        ticks: {
          display: false,
          beginAtZero: false,
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
  }

  return (
    <>
      <div className="chartContainer">
        <div className="monthSelectContainer">
          <div style={{ textAlign: 'center' }}>
            {moment(datePeriod).format(dateFilter === 'Month' ? 'MMMM YYYY' : 'YYYY')}
          </div>
        </div>

        <Bar options={options} data={data} ref={chartRef} />
      </div>
      <div className="title3 smText" style={{ textAlign: 'center' }}>
        Your carbon footprint for {dateFilter === 'Month' ? moment(datePeriod).format('MMMM') : 'this year'} so far is{' '}
        <div className="co2Number">
          <strong>{dateFilter === 'Month' ? chartDataValue : userData?.year_total?.kg_co2e} KG</strong> of CO2e
        </div>
      </div>
      <FootprintStats userData={userData} dateFilter={dateFilter} />
    </>
  )
}
