import Papa from 'papaparse'
import { getUrl } from 'aws-amplify/storage'

export function loadReportTransactionsCSV(): Promise<any> {
  return new Promise((resolve, reject) => {
    getUrl({ key: 'sampleReportTransactions.csv' })
      .then((result: any) => {
        Papa.parse(result.url.href, {
          header: true,
          download: true,
          complete: function (results: any) {
            const formatedData = formatData(results.data)
            resolve(formatedData)
          },
        })
      })
      .catch((err: string) => {
        reject(`Error loading the report transactions CSV data (${err})`)
      })
  })
}

function formatData(data: any) {
  let result: any[] = []
  data.forEach((txn: any) => {
    if (txn['TRAN_AUTH_DT']) {
      const date = txn['TRAN_AUTH_DT'].split('-')
      result.push({
        transactionId: txn['TRAN_ID_NUM'],
        mcc: txn['MRCH_CAT_CODE'],
        price: +txn['TRAN_CRNCY_AMT'],
        currencyISO: txn['ACCT_BASE_CRNCY_CDE'],
        transactionDate: date[2] + '-' + date[1] + '-' + date[0],
      })
    }
  })
  return result
}
