import Papa from 'papaparse'
import { getUrl } from 'aws-amplify/storage'

export function loadTimeSeriesTransactionsCSV(userType: string): Promise<any> {
  return new Promise((resolve, reject) => {
    getUrl({ key: `${userType}.csv` })
      .then((result: any) => {
        Papa.parse(result.url.href, {
          header: true,
          download: true,
          complete: function (results: any) {
            const formatedData = formatData(results.data)
            resolve(formatedData)
          },
        })
      })
      .catch((err: string) => {
        reject(`Error loading the KBC user transactions CSV data (${err})`)
      })
  })
}

function formatData(data: any) {
  let result: any[] = []
  data.forEach((txn: any) => {
    result.push({
      transactionId: txn['transactionId'],
      transactionDate: txn['transactionDate'],
      mcc: txn['mcc'],
      price: Number(txn['price']),
      currencyISO: txn['currencyISO'],
    })
  })
  return result
}
