import { useAuthenticator } from '@aws-amplify/ui-react'
import { useRef } from 'react'

import logo from '../images/logo.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { LineChart } from '../components/LineChart'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import useScrollTriggeredCountUp from '../components/useScrollTriggeredCountUp'
import virtueBankLogo from '../images/virtueBank.png'
import virtueInsuranceLogo from '../images/virtueInsurance.png'
import virtueAccountingLogo from '../images/virtueAccounting.png'
interface UsecaseConfig {
  [key: string]: string
}

const usecasesConfig: UsecaseConfig = {
  Bank: virtueBankLogo,
  'Insurance-associated': virtueInsuranceLogo,
  Accounting: virtueAccountingLogo,
}

export default function AdminView() {
  const { authStatus, signOut } = useAuthenticator((context) => [context.user])

  const navigate = useNavigate()
  const location = useLocation()

  const refFinEmissions = useRef<HTMLDivElement>(null)
  const countNumberFinEmissions = useScrollTriggeredCountUp(refFinEmissions, 51)
  const countDecimalsFinEmissions = useScrollTriggeredCountUp(refFinEmissions, 46)

  const refPCAF = useRef<HTMLDivElement>(null)
  const countNumberPCAF = useScrollTriggeredCountUp(refPCAF, 2)
  const countDecimalsPCAF = useScrollTriggeredCountUp(refPCAF, 23)
  const countGraphicPCAF = useScrollTriggeredCountUp(refPCAF, 44)

  const refPortfolioValue = useRef<HTMLDivElement>(null)
  const countNumberPortfolioValue = useScrollTriggeredCountUp(refPortfolioValue, 51)
  const countDecimalsPortfolioValue = useScrollTriggeredCountUp(refPortfolioValue, 46)

  const refSME = useRef<HTMLDivElement>(null)
  const countNumberSME = useScrollTriggeredCountUp(refSME, 50)
  const countDecimalsSME = useScrollTriggeredCountUp(refSME, 23)

  let selectedUsecase: string = localStorage.getItem('demoType') || 'Bank'
  const clientOrPortfolioUsed = ['Bank', 'Insurance-associated'].includes(selectedUsecase) ? 'Portfolio' : 'Clients'
  const logoUrl = localStorage.getItem('logo') || usecasesConfig[selectedUsecase]
  const isPCAFRelevant = ['Bank', 'Insurance-associated'].includes(selectedUsecase) ? true : false

  const handleUsecaseChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    localStorage.setItem('demoType', event.target.value)
    window.location.reload()
  }

  return (
    <>
      <div className="mainView">
        <div className="topBar">
          <img src={localStorage.getItem('logo') || logoUrl} alt="Connect Earth" className="logoAdminView" />
          <div>
            <button className="dashboardButton margin-right-130" onClick={() => navigate('/')}>
              Home
            </button>
            <div className="dropdownContainerReport">
              <select onChange={handleUsecaseChange} value={selectedUsecase} className="usecaseDropdownReport">
                <option value="Bank">Bank</option>
                <option value="Insurance-associated">Insurance</option>
                <option value="Accounting">Accounting</option>
              </select>
            </div>
          </div>
        </div>{' '}
        <div className="scrollable">
          <div className="portfolioReportSection">
            <p className="sectionTitle">SME {localStorage.getItem('demoType') || 'Bank'} Emissions Report</p>
            <div className="portfolioReportSectionDashboard">
              <div>
                <p>Total Emissions</p>
                <h1 className="sectionResult">17.8M</h1>
              </div>
              <div>
                <h3>Total Scope 1 and 2</h3>
                <h1 className="sectionResult">927.3K</h1>
              </div>
              <div>
                <h3>{clientOrPortfolioUsed} Emissions Intensity</h3>
                <h1 className="sectionResult">0.858</h1>
              </div>
              <div>
                <h3>% of {clientOrPortfolioUsed} Submitted</h3>
                <h1 className="sectionResult">51.5%</h1>
              </div>
            </div>
          </div>

          <div className="dashboardSection">
            <p className="sectionSubTitle">Total Emissions Reported</p>
            <p className="ml6 mt2 mb1">All figures in Metric Tonnes</p>
            <div className="totalEmissionsSectionDashboard">
              <div className="tile">
                <h3 className=" mt2 mb1">Total Emissions</h3>
                <h1 className="sectionResult px55Text mt2 mb1">17.8M</h1>
                <div className="threeScopesDiv">
                  <div>
                    <h3 className="mt2 mb1">Scope 1</h3>
                    <h1 className="sectionSubResult mt2 mb1">3.5M</h1>
                  </div>
                  <div>
                    <h3 className="mt2 mb1">Scope 2</h3>
                    <h1 className="sectionSubResult mt2 mb1">814.6K</h1>
                  </div>
                  <div>
                    <h3 className="mt2 mb1">Scope 3</h3>
                    <h1 className="sectionSubResult mt2 mb1">13.6M</h1>
                  </div>
                </div>
                <h3 className="mt2 mb1">Total Emissions per Revenue (K EUR)</h3>
                <h1 className="sectionResult xlText mt2 mb1">0.872</h1>
              </div>
              <div className="tile">
                <h3 className="mt2 mb1">Median Emissions</h3>
                <h1 className="sectionResult px55Text mt2 mb1">212.4</h1>
                <div className="threeScopesDiv">
                  <div>
                    <h3 className="mt2 mb1">Scope 1</h3>
                    <h1 className="sectionSubResult mt2 mb1">36.6</h1>
                  </div>
                  <div>
                    <h3 className="mt2 mb1">Scope 2</h3>
                    <h1 className="sectionSubResult mt2 mb1">26.3</h1>
                  </div>
                  <div>
                    <h3 className="mt2 mb1">Scope 3</h3>
                    <h1 className="sectionSubResult mt2 mb1">202.1</h1>
                  </div>
                </div>
                <h3 className="mt2 mb1">Total Emissions per FTE</h3>
                <h1 className="sectionResult xlText mt2 mb1">251.7</h1>
              </div>
            </div>
          </div>

          <div className="separator"></div>

          <div className="dashboardSectionFinancedEmissions">
            <h2 className="sectionSubTitle">
              {localStorage.getItem('demoType') || 'Bank'} Emissions from SME Customers
            </h2>
            <p className="lgText mt2 mb1 ml6">All figures in Metric Tonnes</p>
            <div className="totalEmissionsSectionDashboard">
              <div className="tileTotalEmissions">
                <h3 className="sectionSubResult pt6 mt2 mb1">Scope 1 and 2</h3>
                <h1 className="sectionResult px45Text mt2 mb1">927.3K</h1>
                <h3 className="sectionSubResult pt6 mt2 mb1">Scope 3</h3>
                <h1 className="sectionResult px45Text mt2 mb1">3.4M</h1>
              </div>

              <div className="progressFinancedEmissionsDiv" ref={refFinEmissions}>
                <img
                  className="pcafImg"
                  src="pcaflogo.svg"
                  alt=""
                  style={{ display: isPCAFRelevant ? 'block' : 'none' }}
                />
                <CircularProgressbar
                  value={countNumberFinEmissions}
                  text={`${clientOrPortfolioUsed.toUpperCase()} VALUE COVERED ${countNumberFinEmissions}.${countDecimalsFinEmissions}%`}
                  circleRatio={0.5}
                  styles={buildStyles({
                    rotation: 0.75,
                    pathColor: '#5bb030',
                    textColor: '#091026',
                    textSize: '4px',
                  })}
                />
              </div>
            </div>
          </div>

          <div className="divider" />

          <div className="dashboardSection">
            <h2 className="sectionSubTitle mt2 mb1">Weighted Emissions Intensities of {clientOrPortfolioUsed}</h2>
            <p className="lgText ml6 mt2 mb1">All figures in Metric Tonnes per Thousand Euros Revenue</p>
            <div className="portfolioWeightedSectionDashboard">
              <div>
                <h3 className="mt2 mb1">Scope 1 and 2</h3>
                <h1 className="sectionResult px45Text mt2 mb1">0.185</h1>
              </div>
              <div>
                <h3 className="mt2 mb1">Total</h3>
                <h1 className="sectionResult px45Text mt2 mb1">0.858</h1>
              </div>
            </div>
          </div>

          <div className="separator"></div>

          <div className="dashboardSectionPortfolioPCAF" style={{ display: isPCAFRelevant ? 'block' : 'none' }}>
            <h2 className="sectionSubTitle mt2 mb1">{clientOrPortfolioUsed} PCAF Data Quality Score</h2>
            <div className="portfolioPcafSectionDashboard">
              <div>
                <h3 className="sectionResult px45Text mt2" ref={refPCAF}>
                  {countNumberPCAF}.{countDecimalsPCAF}
                </h3>
                <div className="progressPortfolioPcafDiv">
                  <CircularProgressbar
                    value={countGraphicPCAF}
                    text={`Portfolio Data Quality Score`}
                    circleRatio={0.5}
                    styles={buildStyles({
                      rotation: 0.75,
                      pathColor: '#5bb030',
                      textColor: '#091026',
                      textSize: '5px',
                    })}
                  />
                </div>
              </div>
              <div>
                <img className="pcafImgChart" src="chartPcaf.png" alt="" />
              </div>
            </div>
          </div>

          <div className="separator"></div>

          <div className="dashboardSection">
            <h2 className="sectionSubTitle  mt2 mb1">Number of SMEs that have submitted data</h2>
            <div className="numberOfSmeSectionDashboard">
              <div>
                <h3 className="sectionResult px65Text  mt2 mb1">2,724</h3>
                <div className="twoProgressBars">
                  <div>
                    <h3 className="sectionResult xlText  mt2 mb1" ref={refPortfolioValue}>
                      {countNumberPortfolioValue}.{countDecimalsPortfolioValue}%
                    </h3>
                    <div className="progressNumberOfSmeDiv">
                      <CircularProgressbar
                        value={countNumberPortfolioValue}
                        text={`${clientOrPortfolioUsed.toUpperCase()} VALUE`}
                        circleRatio={0.5}
                        styles={buildStyles({
                          rotation: 0.75,
                          pathColor: '#5bb030',
                          textColor: '#091026',
                          textSize: '6px',
                        })}
                      />
                    </div>
                  </div>

                  <div>
                    <h3 className="sectionResult xlText  mt2 mb1" ref={refSME}>
                      {countNumberSME}.{countDecimalsSME}%
                    </h3>
                    <div className="progressNumberOfSmeDiv">
                      <CircularProgressbar
                        value={countNumberSME}
                        text={`SME COUNT`}
                        circleRatio={0.5}
                        styles={buildStyles({
                          rotation: 0.75,
                          pathColor: '#5bb030',
                          textColor: '#091026',
                          textSize: '6px',
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <LineChart />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
