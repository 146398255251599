export const chartColors = [
  '#003f5c',
  '#f95d6a',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#ff7c43',
  '#ffa600',
  '#00876c',
  '#6aaa96',
  '#aecdc2',
  '#f1f1f1',
  '#f0b8b8',
  '#e67f83',
  '#d43d51',
]
