import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { Authenticator } from '@aws-amplify/ui-react'
import Login from './routes/Login'
import Home from './routes/Home'
import Report from './routes/Report'
import Insights from './routes/Insights'
import Settings from './routes/Settings'
import Offsets from './routes/Offset'
import AdminView from './routes/AdminView'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  { path: '/insights', element: <Insights /> },
  { path: '/removals', element: <Offsets /> },
  {
    path: '/report',
    element: <Report />,
  },
  {
    path: '/settings',
    element: <Settings />,
  },
  {
    path: '/admin-view',
    element: <AdminView />,
  },
])

root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <RouterProvider router={router} />
    </Authenticator.Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
