import {
  AccessToken,
  ReportSession,
} from './../interface/authenticationInterface'
import axios from 'axios'

export function getAccessToken(): Promise<AccessToken> {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_CONNECT_API_URL + '/report/token',
      headers: {
        'x-api-key': process.env.REACT_APP_CONNECT_API_KEY,
      },
    })
      .then((res: any) => {
        resolve(res.data)
      })
      .catch((err: any) => {
        reject(`Error getting the access token (${err})`)
      })
  })
}

export function getReportSession(
  token: String,
  userId: string,
): Promise<ReportSession> {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_CONNECT_API_URL + '/report/session',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: { userId },
    })
      .then((res: any) => {
        resolve(res.data)
      })
      .catch((err: any) => {
        reject(`Error getting the session (${err})`)
      })
  })
}
