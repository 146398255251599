import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCar, faUsers, faLeaf } from '@fortawesome/free-solid-svg-icons'
export default function FootprintStats({ userData, dateFilter }: { userData: any; dateFilter: string }) {
  const averageCitizenPerMonth = userData?.year_average_citizen_kg_co2e / 12

  const percentageChangeByMonth = Math.round(
    ((userData?.month_to_date_comparison?.previous_month?.kg_co2e -
      userData?.month_to_date_comparison?.current_month?.kg_co2e) /
      userData?.month_to_date_comparison?.previous_month?.kg_co2e) *
      100,
  )

  const percentageChangeByYear = Math.round(
    ((userData?.year_to_date_comparison?.previous_year?.kg_co2e -
      userData?.year_to_date_comparison?.current_year?.kg_co2e) /
      userData?.year_to_date_comparison?.previous_year?.kg_co2e) *
      100,
  )

  const percentageChangeByAverageCitizenByMonth = Math.round(
    ((averageCitizenPerMonth - userData?.month_to_date_comparison?.current_month?.kg_co2e) / averageCitizenPerMonth) *
      100,
  )

  const percentageChangeByAverageCitizenByYear = Math.round(
    ((userData?.year_average_citizen_kg_co2e - userData?.year_total?.kg_co2e) /
      userData?.year_average_citizen_kg_co2e) *
      100,
  )

  const equivalentByPetrolCar =
    dateFilter === 'Month'
      ? userData?.month_to_date_comparison?.current_month?.equivalents[0]
      : userData?.year_total?.equivalents[0]
  const equivalentByTrees =
    dateFilter === 'Month'
      ? userData?.month_to_date_comparison?.current_month?.equivalents[1]
      : userData?.year_total?.equivalents[1]

  const distanceAroundWorldKm = 40075
  const distanceFromBrusselsToParisKm = 317.5

  const aroundWorldEquivalent = Math.round(equivalentByPetrolCar?.value / distanceAroundWorldKm)

  const userType = localStorage.getItem('userType') === 'business' ? 'business' : 'citizen'

  return (
    <div className="footprintStats">
      <div className="footprintStat smText">
        {<FontAwesomeIcon icon={faCalendar} size="1x" fixedWidth />}
        {dateFilter === 'Month' ? (
          <>
            <strong>
              {Math.abs(percentageChangeByMonth) > 100 ? '>100%' : Math.abs(percentageChangeByMonth) + '%'}
              {percentageChangeByMonth > 0 ? ' lower ' : ' higher '}
            </strong>
            than the previous month
          </>
        ) : (
          <>
            <strong>
              {Math.abs(percentageChangeByYear) > 100 ? '>100%' : Math.abs(percentageChangeByYear) + '%'}
              {percentageChangeByYear > 0 ? ' lower ' : ' higher '}
            </strong>
            than the previous year
          </>
        )}
      </div>
      <div className="footprintStat smText">
        {<FontAwesomeIcon icon={faUsers} size="1x" fixedWidth />}{' '}
        {dateFilter === 'Month' ? (
          <>
            <strong>
              {' '}
              {Math.abs(percentageChangeByAverageCitizenByMonth) + '%'}
              {percentageChangeByAverageCitizenByMonth > 0 ? ' lower ' : ' higher '}
            </strong>
            than the average {userType}
          </>
        ) : (
          <>
            <strong>
              {' '}
              {Math.abs(percentageChangeByAverageCitizenByYear) + '%'}
              {percentageChangeByAverageCitizenByYear > 0 ? ' lower ' : ' higher '}
            </strong>
            than the average {userType}
          </>
        )}
      </div>
      <div className="footprintStat smText">
        {<FontAwesomeIcon icon={faCar} size="1x" fixedWidth />}
        Equivalent to driving a petrol car{' '}
        <strong>
          {dateFilter === 'Month'
            ? `from Brussels to Paris ${Math.round(equivalentByPetrolCar?.value / distanceFromBrusselsToParisKm)} times`
            : `around the world ${
                aroundWorldEquivalent >= 2 || aroundWorldEquivalent < 1 ? `${aroundWorldEquivalent} times` : `once`
              }`}
        </strong>
      </div>
      <div className="footprintStat smText">
        {<FontAwesomeIcon icon={faLeaf} size="1x" fixedWidth />} It takes{' '}
        <strong>{Math.round(equivalentByTrees?.value)} trees</strong> one year to absorb this amount of CO2
      </div>
    </div>
  )
}
