import { faBuildingColumns } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Notification() {
  return (
    <>
      <div className="notification">
        <div className="notification-container">
          <div className="notification-content">
            <div className="notification-title">
              <div className="notification-company">
                <FontAwesomeIcon icon={faBuildingColumns} />
                <p>Bank Name</p>
              </div>
              <p>2 min</p>
            </div>

            <div>
              <div>
                Taking international rail instead of short-haul flights could reduce your travel emissions by up to 95%.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
