import axios from 'axios'
import { ApiBatchTransactionResult } from '../interface/transactionInterface'

export function getBatchEstimates(
  data: string[],
): Promise<ApiBatchTransactionResult[]> {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_CONNECT_API_URL + '/transaction/bulk/upload/get',
      headers: {
        'x-api-key': process.env.REACT_APP_CONNECT_API_KEY,
      },
      data: {
        transactions: data,
      },
    })
      .then((res: any) => {
        resolve(res.data)
      })
      .catch((err: any) => {
        reject(`Error getting estimates from the API (${err})`)
      })
  })
}
