import { faLeaf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { currenciesList } from '../helpers/currencyList'
import { Transaction } from '../interface/transactionInterface'

export default function TransactionDetail({
  closePopup,
  transaction,
}: {
  closePopup: Function
  transaction: Transaction
}) {
  function iconStyle() {
    if (transaction.iconType === 'faIcon') {
      return {
        backgroundColor: transaction.category.color,
        width: '60px',
        height: '60px',
      }
    }
  }

  return (
    <div className="transactionModal">
      <div className="flexColumn transactionModalContent">
        <span className="closeButton" onClick={() => closePopup()}>
          &times;
        </span>
        <div className="detailIcon" style={iconStyle()}>
          {transaction.icon}
        </div>
        <div className="xlText mTop1">{transaction.description}</div>
        <div className="mGray mTop1">{new Date(transaction.date || '').toLocaleString()}</div>
        <div className="xlText mTop1">
          {Number(transaction?.amount) > 0 ? '+' : '-'}
          {currenciesList[localStorage.getItem('currency') || 'GBP'].symbol}
          {Math.abs(Number(transaction?.amount)).toFixed(2)}
        </div>
        <div className="dgText mTop1 smText">{transaction.merchant}</div>
        <div className="dgText smText">{transaction.category.name}</div>
        <div className="flexRow detailImpact">
          <div>Impact</div>
          <div className="">
            <FontAwesomeIcon icon={faLeaf} color="var(--leaf-color)" size="sm" />
            {'  '}
            {transaction.kgCo2 ? transaction.kgCo2 : '-'} <span>KG CO2</span>
          </div>
        </div>
        <div className="smText dgText">{transaction.similarTo?.[0] || ''}</div>
      </div>
    </div>
  )
}
