import { ApiChartOutput, ApiChartTransactionInput } from './../interface/chartsInterface'
import axios from 'axios'
import { Transaction } from '../interface/transactionInterface'
import getModifiers from './getModifiers'

export function getChartData(
  data: Transaction[],
  groupSelector: string,
  region: string = 'uk',
  lang: string = 'eng',
): Promise<ApiChartOutput> {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_CONNECT_API_URL + '/charts/pie',
      headers: {
        'x-api-key': process.env.REACT_APP_CONNECT_API_KEY,
      },
      data: {
        ...getModifiers(),
        transactions: mapTransactions(data, groupSelector),
      },
      params: {
        region,
        lang,
      },
    })
      .then((res: any) => {
        resolve(res.data)
      })
      .catch((err: any) => {
        reject(`Error getting estimates from the API (${err})`)
      })
  })
}

function mapTransactions(data: Transaction[], groupSelector: string): ApiChartTransactionInput[] {
  const transactions: ApiChartTransactionInput[] = []
  data.forEach((transaction: Transaction) => {
    transactions.push({
      price: Math.abs(+transaction.price),
      mcc: transaction.mcc,
      merchant: transaction.merchant || '',
      currencyISO: localStorage.getItem('currency') || 'GBP',
      group: groupSelector === 'Category' ? transaction.category?.name : transaction.merchant,
    })
  })
  return transactions
}
