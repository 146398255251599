import { useAuthenticator } from '@aws-amplify/ui-react'
import { faSignal, faWifi, faBattery } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import BottomNavBar from './BottomNavBar'
import logo from '../images/logo.png'
import useMediaQuery from '../hooks/useMediaQuery'
import { useLocation, useNavigate } from 'react-router-dom'

export default function MobileFrame({ content }: { content: ReactElement }) {
  const { authStatus, signOut } = useAuthenticator((context) => [context.user])
  const isMobile = useMediaQuery('(max-width: 480px)')

  const navigate = useNavigate()
  const location = useLocation()

  if (authStatus !== 'authenticated') {
    return <Navigate to="/login" />
  }

  if (isMobile) {
    return (
      <>
        <div className="mainView">
          <div className="topBar">
            <img src={localStorage.getItem('logo') || logo} alt="Connect Earth" className="logo" />
            <div>
              <button className="dashboardButton smText" onClick={() => navigate('/admin-view')}>
                Admin View
              </button>
              <button className="primaryButton smText" onClick={() => signOut()}>
                Sign out
              </button>
            </div>
          </div>{' '}
          {content}
          <BottomNavBar />
        </div>
      </>
    )
  }

  return (
    <>
      <div className="topBar">
        <img src={localStorage.getItem('logo') || logo} alt="Connect Earth" className="logo" />
        <div>
          <button className="dashboardButton" onClick={() => navigate('/admin-view')}>
            Admin View
          </button>
          <button className="primaryButton" onClick={() => signOut()}>
            Sign out
          </button>
        </div>
      </div>{' '}
      <div className="mobileView">
        <div className="statusBar">
          <div className="mobileClock smText">10:30</div>
          <div className="mobileCamera">
            <div className="mobileCameraDot"></div>
          </div>
          <div className="flexRow mobileIcons">
            <FontAwesomeIcon icon={faSignal} size="xs" />
            <FontAwesomeIcon icon={faWifi} size="xs" />
            <FontAwesomeIcon icon={faBattery} size="xs" />
          </div>
        </div>
        {content}
        <BottomNavBar />
      </div>
    </>
  )
}
