import { TimeSeriesChartOutput } from './../interface/chartsInterface'
import axios from 'axios'

export function getTimeSeriesChartData(date: String): Promise<TimeSeriesChartOutput> {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_CONNECT_API_URL + '/transaction/bulk/timeseries/chart',
      headers: {
        'x-api-key': process.env.REACT_APP_CONNECT_API_KEY,
      },
      data: {
        userId: [localStorage.getItem('dashboard_user_type') || 'baseline_user'],
        date: date,
        geo: localStorage.getItem('country') || 'BE',
        fixedEquivalents: ['car', 'tree'],
      },
    })
      .then((res: any) => {
        resolve(res.data)
      })
      .catch((err: any) => {
        reject(`Error getting estimates from the API (${err})`)
      })
  })
}
