import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment, { Moment } from 'moment'
import { ChangeEvent, useEffect, useState } from 'react'

export default function DateNavigationBar({ setPeriod, isInsights }: any) {
  const [granularity, setGranularity] = useState<any>('month')
  const [date, setDate] = useState<Moment>(isInsights ? moment('2023-12-31') : moment())

  const granularities: any = {
    month: {
      name: 'Month',
      display: () => {
        return date.format('MMMM YYYY')
      },
    },
    year: {
      name: 'Year',
      display: () => {
        return date.format('YYYY')
      },
    },
  }

  function changeDate(inc: number) {
    const unit: any = granularity + 's'
    setDate(moment(date.add(inc, unit)))
  }

  function changeGranularity(event: ChangeEvent<HTMLSelectElement>) {
    setGranularity(event.target.value)
  }

  useEffect(() => {
    setPeriod({
      start: moment(date).startOf(granularity),
      end: moment(date).endOf(granularity),
      display: granularities[granularity].display(),
      filterType: granularities[granularity].name,
    })
    // TODO: check if there's a better way to avoid this warning. It happens when a variable or function used inside the hook is not explicitly added to the dependency array. In this case it's not possible to add the `setPeriod` as a dependency as it would cause an infinite loop of renders. As far as I've reed using a `useState` function inside the `useEffect` is not a bad practice, but maybe there's a better option.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, granularity])

  return (
    <div className="flexRow dateNavigationContainer smText">
      <select className="granularitySelector clickable" onChange={changeGranularity} value={granularity}>
        {Object.keys(granularities).map((granularityKey: string) => {
          return (
            <option key={granularityKey} value={granularityKey}>
              {granularities[granularityKey].name}
            </option>
          )
        })}
      </select>
      <FontAwesomeIcon icon={faChevronLeft} className="clickable" onClick={() => changeDate(-1)} />
      <div className="selectedPeriod">{granularities[granularity].display(date)}</div>
      <FontAwesomeIcon icon={faChevronRight} className="clickable" onClick={() => changeDate(1)} />
    </div>
  )
}
