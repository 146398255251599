const userTypesMap: any = {
  consumer: 'PERSONAL',
  business: 'BUSINESS',
}

const dietaryHabitsMap: any = {
  vegan: 'VEGAN',
  vegetarian: 'VEGETARIAN',
  pescatarian: 'PESCATARIAN',
}

const carHabitsMap: any = {
  noCar: 'NO_CAR',
}

export default function getModifiers() {
  const modifiers: any = {
    geo: localStorage.getItem('country') || 'GB',
    userType: userTypesMap[localStorage.getItem('userType') || 'consumer'],
    emissionsFactorsVersion: localStorage.getItem('emissions_factor_version') || 'v1_1',
  }
  const dietary = localStorage.getItem('dietaryHabits')
  if (dietary && dietary !== 'na') {
    modifiers['user_habits'] = {
      dietary: dietaryHabitsMap[dietary],
    }
  }
  const car = localStorage.getItem('carHabits')
  if (car && car !== 'na') {
    modifiers['car_habits'] = {
      car: carHabitsMap[car],
    }
  }
  return modifiers
}
