import { Offset } from './../interface/offsetInterface'
import axios from 'axios'

export function getSessionId(username: string): Promise<string> {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_CONNECT_API_OFFSETS_URL + '/offset/auth?anonymisedUserId=' + username,
      headers: {
        'x-api-key': process.env.REACT_APP_CONNECT_API_OFFSETS_KEY,
      },
    })
      .then((res: any) => {
        resolve(res.data.sessionId)
      })
      .catch((err: any) => {
        reject(`Error getting offsets session from the API (${err})`)
      })
  })
}

export function getOffsets(username: string): Promise<Offset[]> {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_CONNECT_API_OFFSETS_URL + '/offset/user/' + username,
      headers: {
        'x-api-key': process.env.REACT_APP_CONNECT_API_OFFSETS_KEY,
      },
    })
      .then((res: any) => {
        resolve(res.data)
      })
      .catch((err: any) => {
        reject(`Error getting offsets session from the API (${err})`)
      })
  })
}
