import { useState, useEffect, useRef } from 'react'
import { getChartData } from '../api/chartsRequest'
import { categories, mccToCategory } from '../helpers/categoryMapping'
import { ApiChartOutput, Group } from '../interface/chartsInterface'
import { Transaction } from '../interface/transactionInterface'
import BreakdownChart from '../components/BreakdownChart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartPie, faFilter } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import BreakdownDetail from '../components/BreakdownDetail'
import DateNavigationBar from '../components/DateNavigationBar'
import MobileFrame from '../components/MobileFrame'
import moment from 'moment'
import { getBatchChartData } from '../api/batchChartsRequest'
import Recommendations from '../components/Recommendation'
import Notification from '../components/Notification'
import AccountBalance from '../components/AccountBalance'
import mayRecommendations from '../fixtures/may_recommendations.json'
import juneRecommendations from '../fixtures/june_recommendations.json'
import DashboardChart from '../components/DashboardChart'
import { loadTimeSeriesTransactionsCSV } from '../helpers/loadTimeSeriesTransactionsCSV'
import Carousel from '../components/Carousel'

export default function Insights() {
  const [csvData, setCsvData] = useState<Transaction[]>([])
  const [data, setData] = useState<any>({})
  const breakdownOptions: string[] = ['Category', 'Merchant']
  const tabs: string[] = ['Breakdown', 'Dashboard', 'Insights']
  const [selectedBreakdown, setSelectedBreakdown] = useState<string>(breakdownOptions[0])
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0])
  const [categoryData, setCategoryData] = useState<any>([])
  const [showDateNavigation, setShowDateNavigation] = useState<boolean>(false)
  const [period, setPeriod] = useState<any>({
    start: moment('2023-12-31').startOf('month').format('YYYY-MM-DD'),
    end: moment('2023-12-31').endOf('month').format('YYYY-MM-DD'),
    display: moment('2023-12-31').format('MMMM YYYY'),
    filterType: 'Month',
  })
  const notificationButtonDisplayText = 'Open notification'
  const [showNotification, setShowNotification] = useState(false)
  const [region, setRegion] = useState('uk')
  const [language, setLanguage] = useState('eng')
  const [showInformation, setShowInformation] = useState<boolean>(false)
  const ref = useRef<any>()

  const informationButtonDisplayText = 'How do our calculations work?'

  useEffect(() => {
    loadTimeSeriesTransactionsCSV(localStorage.getItem('dashboard_user_type') || 'baseline_user')
      .then((transactions: Transaction[]) => {
        setCsvData(transactions)
      })
      .catch((err: any) => {
        console.error(err)
      })
  }, [])

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showNotification && ref.current && !ref.current.contains(e.target)) {
        setShowNotification(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showNotification])

  useEffect(() => {
    const transactions = csvData.filter((transaction: any) =>
      moment(transaction.transactionDate).isBetween(period.start, period.end, undefined, '[]'),
    )
    if (transactions.length && transactions.every((transaction) => transaction.price !== (0 as any))) {
      addCategory(transactions)
      if (selectedTab === 'Dashboard') {
        localStorage.getItem('dataSource') === 'batchUpload'
          ? getBatchChartData(transactions, 'Category').then(async (chartData: ApiChartOutput) => {
              await addIcons(chartData, transactions)
              setCategoryData(chartData)
            })
          : getChartData(transactions, 'Category', selectRegion(language), language).then(
              async (chartData: ApiChartOutput) => {
                await addIcons(chartData, transactions)
                setCategoryData(chartData)
              },
            )
      }

      addCategory(transactions)
      localStorage.getItem('dataSource') === 'batchUpload'
        ? getBatchChartData(transactions, selectedBreakdown).then(async (chartData: ApiChartOutput) => {
            await addIcons(chartData, transactions)
            setData(chartData)
          })
        : getChartData(transactions, selectedBreakdown, selectRegion(language), language).then(
            async (chartData: ApiChartOutput) => {
              await addIcons(chartData, transactions)
              setData(chartData)
            },
          )
    } else {
      setData([])
    }
  }, [csvData, period, selectedTab, selectedBreakdown, region, language])

  const handleChange = (e: any) => {
    setSelectedBreakdown(e.target.value)
  }

  const selectRegion = (language: string) => {
    if (language === 'eng') {
      return 'uk'
    } else {
      return 'be'
    }
  }

  const selectFixture = (month: any) => {
    if (month === 'May 2023') {
      return mayRecommendations
    } else if (month === 'June 2023') {
      return juneRecommendations
    } else {
      return data
    }
  }

  return (
    <MobileFrame
      content={
        <>
          <div className="viewHeader" ref={ref}>
            <div className="title1 bold">
              <div>
                {' '}
                <FontAwesomeIcon icon={faChartPie} className="mRight1" />
                INSIGHTS
              </div>
              <FontAwesomeIcon
                className="clickable"
                icon={faFilter}
                onClick={() => setShowDateNavigation((currentValue) => !currentValue)}
              />
            </div>
            {showDateNavigation && <DateNavigationBar setPeriod={setPeriod} isInsights={true} />}
            <AccountBalance />
            <div
              className="moreInfoButton smText"
              onClick={() => {
                setShowInformation(true)
              }}
            >
              {informationButtonDisplayText}
            </div>
            {showInformation && <Carousel showInformation={setShowInformation} />}
            <div className="flexRow">
              {tabs.map((tab: string) => {
                return (
                  <div
                    key={tab}
                    className={selectedTab === tab ? 'pillButton smText tabSelected' : 'pillButton smText'}
                    onClick={() => setSelectedTab(tab)}
                  >
                    {tab}
                  </div>
                )
              })}
            </div>
          </div>
          {selectedTab === 'Dashboard' && (
            <>
              <div className="flexColumn scrollable mTop1">
                <DashboardChart
                  dateFilter={period.filterType}
                  datePeriod={
                    period.filterType === 'Month'
                      ? moment(period.display).format(`YYYY-MM-${moment(period.display).daysInMonth()}`)
                      : period.display
                  }
                />
              </div>
            </>
          )}
          {selectedTab === 'Breakdown' && (
            <div className="flexColumn scrollable mTop1">
              <BreakdownChart data={data.groups} level={selectedBreakdown} periodDisplay={period.display} />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="title2 smText">BREAKDOWN</div>
                <select
                  className="title2 smText"
                  value={selectedBreakdown}
                  onChange={handleChange}
                  style={{ border: 'none' }}
                >
                  {breakdownOptions.map((option: string) => {
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    )
                  })}
                </select>
              </div>

              <div className="flexColumn scrollable mTop1">
                <BreakdownDetail groups={data.groups} />
              </div>
            </div>
          )}
          {selectedTab === 'Insights' && (
            <div className="flexColumn">
              <div className="title3 lgText" style={{ textAlign: 'center', fontWeight: 'bold' }}>
                Your Carbon Footprint Insights
              </div>
              <div className="title3 smText" style={{ textAlign: 'center', fontWeight: 'bold', margin: '5px' }}>
                Based on your spending, here are some tips to reduce your carbon footprint
              </div>
              {data.length === 0 ? (
                <div className="padH1 mTop1 dgText smText">No transactions for this period.</div>
              ) : (
                <div className="flexColumn scrollable mTop1">
                  <Recommendations
                    data={localStorage.getItem('userType') === 'business' ? selectFixture(period.display) : data}
                    setLanguage={setLanguage}
                  />
                  <div>
                    <div className="title2 ucText mTop1 smText">Notification example</div>
                    <div
                      className="secondaryButton smText"
                      onClick={() => {
                        setShowNotification(true)
                      }}
                    >
                      {notificationButtonDisplayText}
                    </div>
                    {showNotification && <Notification />}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      }
    ></MobileFrame>
  )

  function addCategory(rawTransactions: Transaction[]) {
    rawTransactions.forEach((transaction: Transaction) => {
      transaction.category = categories[mccToCategory[transaction.mcc]]
    })
  }

  async function addIcons(data: ApiChartOutput, transactions: Transaction[]) {
    const promises: Promise<void>[] = []
    data.groups.forEach((group: Group) => {
      promises.push(
        new Promise<void>((resolve, reject) => {
          if (categories[group.group]) {
            addCategoryIcon(group, categories[group.group].icon)
            resolve()
          } else {
            const transaction = transactions.find((txn: Transaction) => txn.merchant === group.group)
            const url =
              'https://logo.clearbit.com/' +
              (transaction?.merchantUrl || transaction?.merchant?.replace("'", '') + '.com')
            axios
              .get(url)
              .then(() => {
                addMerchantIcon(group, url)
                resolve()
              })
              .catch(() => {
                addCategoryIcon(group, transaction?.category.icon)
                group.category = transaction?.category
                resolve()
              })
          }
        }),
      )
    })
    await Promise.all(promises)
  }

  function addCategoryIcon(group: Group, icon: any) {
    group.icon = <FontAwesomeIcon icon={icon} width="100%" color="white" />
    group.iconType = 'faIcon'
  }

  function addMerchantIcon(group: Group, url: string) {
    group.icon = <img src={url} alt="." />
    group.iconType = 'img'
  }
}
