import { ApiReportTransactionInput } from './../interface/transactionInterface'
import { post } from './api'

export async function uploadReportTransactions(
  sessionId: string,
  transactions: ApiReportTransactionInput[],
) {
  const maxLength = 200
  for (let i = 0; i < transactions.length; i += maxLength) {
    await post('/report/transactions/upload', {
      sessionId,
      geo: 'US',
      transactions: transactions.slice(i, i + maxLength),
    })
  }
}
