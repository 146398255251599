import {
  faBurger,
  faCar,
  faCartShopping,
  faEllipsis,
  faFileInvoiceDollar,
  faHandHoldingDollar,
  faHeartPulse,
  faHouse,
  faMasksTheater,
  faMoneyBill1Wave,
  faSuitcase,
  faUtensils,
  faPrint,
} from '@fortawesome/free-solid-svg-icons'

const furnitureCategory = localStorage.getItem('userType') === 'business' ? 'Office Furniture' : 'Home Improvements'
const travelCategory = localStorage.getItem('userType') === 'business' ? 'Business Travel' : 'Travel and Holidays'
const shoppingCategory = localStorage.getItem('userType') === 'business' ? 'General Purchases' : 'General Shopping'

export const categories: any = {
  'Travel and Holidays': {
    name: 'Travel and Holidays',
    icon: faSuitcase,
    color: '#003f5c',
  },
  'Business Travel': {
    name: 'Business Travel',
    icon: faSuitcase,
    color: '#003f5c',
  },
  'Car and Transport': {
    name: 'Car and Transport',
    icon: faCar,
    color: '#2f4b7c',
  },
  'General Shopping': {
    name: 'General Shopping',
    icon: faCartShopping,
    color: '#f95d6a',
  },
  'General Purchases': {
    name: 'General Purchases',
    icon: faCartShopping,
    color: '#f95d6a',
  },
  'Culture and Entertainment': {
    name: 'Culture and Entertainment',
    icon: faMasksTheater,
    color: '#a05195',
  },
  'Home Improvements': {
    name: 'Home Improvements',
    icon: faHouse,
    color: '#d45087',
  },
  'Office Furniture': {
    name: 'Office Furniture',
    icon: faPrint,
    color: '#d45087',
  },
  'Healthcare and Sports': {
    name: 'Healthcare and Sports',
    icon: faHeartPulse,
    color: '#665191',
  },
  'Restaurants and Bars': {
    name: 'Restaurants and Bars',
    icon: faUtensils,
    color: '#ff7c43',
  },
  'Bills and Utilities': {
    name: 'Bills and Utilities',
    icon: faFileInvoiceDollar,
    color: '#ffa600',
  },
  Cash: {
    name: 'Cash',
    icon: faMoneyBill1Wave,
    color: '#00876c',
  },
  'Food and Groceries': {
    name: 'Food and Groceries',
    icon: faBurger,
    color: '#6aaa96',
  },
  Services: {
    name: 'Services',
    icon: faHandHoldingDollar,
    color: '#aecdc2',
  },
  Miscellaneous: {
    name: 'Miscellaneous',
    icon: faEllipsis,
    color: '#f1f1f1',
  },
}

export const mccToCategory: any = {
  '3000': travelCategory,
  '3001': travelCategory,
  '3002': travelCategory,
  '3003': travelCategory,
  '3004': travelCategory,
  '3005': travelCategory,
  '3006': travelCategory,
  '3007': travelCategory,
  '3008': travelCategory,
  '3009': travelCategory,
  '3010': travelCategory,
  '3011': travelCategory,
  '3012': travelCategory,
  '3013': travelCategory,
  '3014': travelCategory,
  '3015': travelCategory,
  '3016': travelCategory,
  '3017': travelCategory,
  '3018': travelCategory,
  '3019': travelCategory,
  '3020': travelCategory,
  '3021': travelCategory,
  '3022': travelCategory,
  '3023': travelCategory,
  '3024': travelCategory,
  '3025': travelCategory,
  '3026': travelCategory,
  '3027': travelCategory,
  '3028': travelCategory,
  '3029': travelCategory,
  '3030': travelCategory,
  '3031': travelCategory,
  '3032': travelCategory,
  '3033': travelCategory,
  '3034': travelCategory,
  '3035': travelCategory,
  '3036': travelCategory,
  '3037': travelCategory,
  '3038': travelCategory,
  '3039': travelCategory,
  '3040': travelCategory,
  '3041': travelCategory,
  '3042': travelCategory,
  '3043': travelCategory,
  '3044': travelCategory,
  '3045': travelCategory,
  '3046': travelCategory,
  '3047': travelCategory,
  '3048': travelCategory,
  '3049': travelCategory,
  '3050': travelCategory,
  '3051': travelCategory,
  '3052': travelCategory,
  '3053': travelCategory,
  '3054': travelCategory,
  '3055': travelCategory,
  '3056': travelCategory,
  '3057': travelCategory,
  '3058': travelCategory,
  '3059': travelCategory,
  '3060': travelCategory,
  '3061': travelCategory,
  '3062': travelCategory,
  '3063': travelCategory,
  '3064': travelCategory,
  '3065': travelCategory,
  '3066': travelCategory,
  '3067': travelCategory,
  '3068': travelCategory,
  '3069': travelCategory,
  '3070': travelCategory,
  '3071': travelCategory,
  '3072': travelCategory,
  '3073': travelCategory,
  '3074': travelCategory,
  '3075': travelCategory,
  '3076': travelCategory,
  '3077': travelCategory,
  '3078': travelCategory,
  '3079': travelCategory,
  '3080': travelCategory,
  '3081': travelCategory,
  '3082': travelCategory,
  '3083': travelCategory,
  '3084': travelCategory,
  '3085': travelCategory,
  '3086': travelCategory,
  '3087': travelCategory,
  '3088': travelCategory,
  '3089': travelCategory,
  '3090': travelCategory,
  '3091': travelCategory,
  '3092': travelCategory,
  '3093': travelCategory,
  '3094': travelCategory,
  '3095': travelCategory,
  '3096': travelCategory,
  '3097': travelCategory,
  '3098': travelCategory,
  '3099': travelCategory,
  '3100': travelCategory,
  '3101': travelCategory,
  '3102': travelCategory,
  '3103': travelCategory,
  '3104': travelCategory,
  '3105': travelCategory,
  '3106': travelCategory,
  '3107': travelCategory,
  '3108': travelCategory,
  '3109': travelCategory,
  '3110': travelCategory,
  '3111': travelCategory,
  '3112': travelCategory,
  '3113': travelCategory,
  '3114': travelCategory,
  '3115': travelCategory,
  '3116': travelCategory,
  '3117': travelCategory,
  '3118': travelCategory,
  '3119': travelCategory,
  '3120': travelCategory,
  '3121': travelCategory,
  '3122': travelCategory,
  '3123': travelCategory,
  '3124': travelCategory,
  '3125': travelCategory,
  '3126': travelCategory,
  '3127': travelCategory,
  '3128': travelCategory,
  '3129': travelCategory,
  '3130': travelCategory,
  '3131': travelCategory,
  '3132': travelCategory,
  '3133': travelCategory,
  '3134': travelCategory,
  '3135': travelCategory,
  '3136': travelCategory,
  '3137': travelCategory,
  '3138': travelCategory,
  '3139': travelCategory,
  '3140': travelCategory,
  '3141': travelCategory,
  '3142': travelCategory,
  '3143': travelCategory,
  '3144': travelCategory,
  '3145': travelCategory,
  '3146': travelCategory,
  '3147': travelCategory,
  '3148': travelCategory,
  '3149': travelCategory,
  '3150': travelCategory,
  '3151': travelCategory,
  '3152': travelCategory,
  '3153': travelCategory,
  '3154': travelCategory,
  '3155': travelCategory,
  '3156': travelCategory,
  '3157': travelCategory,
  '3158': travelCategory,
  '3159': travelCategory,
  '3160': travelCategory,
  '3161': travelCategory,
  '3162': travelCategory,
  '3163': travelCategory,
  '3164': travelCategory,
  '3165': travelCategory,
  '3166': travelCategory,
  '3167': travelCategory,
  '3168': travelCategory,
  '3169': travelCategory,
  '3170': travelCategory,
  '3171': travelCategory,
  '3172': travelCategory,
  '3173': travelCategory,
  '3174': travelCategory,
  '3175': travelCategory,
  '3176': travelCategory,
  '3177': travelCategory,
  '3178': travelCategory,
  '3179': travelCategory,
  '3180': travelCategory,
  '3181': travelCategory,
  '3182': travelCategory,
  '3183': travelCategory,
  '3184': travelCategory,
  '3185': travelCategory,
  '3186': travelCategory,
  '3187': travelCategory,
  '3188': travelCategory,
  '3189': travelCategory,
  '3190': travelCategory,
  '3191': travelCategory,
  '3192': travelCategory,
  '3193': travelCategory,
  '3194': travelCategory,
  '3195': travelCategory,
  '3196': travelCategory,
  '3197': travelCategory,
  '3198': travelCategory,
  '3199': travelCategory,
  '3200': travelCategory,
  '3201': travelCategory,
  '3202': travelCategory,
  '3203': travelCategory,
  '3204': travelCategory,
  '3205': travelCategory,
  '3206': travelCategory,
  '3207': travelCategory,
  '3208': travelCategory,
  '3209': travelCategory,
  '3210': travelCategory,
  '3211': travelCategory,
  '3212': travelCategory,
  '3213': travelCategory,
  '3214': travelCategory,
  '3215': travelCategory,
  '3216': travelCategory,
  '3217': travelCategory,
  '3218': travelCategory,
  '3219': travelCategory,
  '3220': travelCategory,
  '3221': travelCategory,
  '3222': travelCategory,
  '3223': travelCategory,
  '3224': travelCategory,
  '3225': travelCategory,
  '3226': travelCategory,
  '3227': travelCategory,
  '3228': travelCategory,
  '3229': travelCategory,
  '3230': travelCategory,
  '3231': travelCategory,
  '3232': travelCategory,
  '3233': travelCategory,
  '3234': travelCategory,
  '3235': travelCategory,
  '3236': travelCategory,
  '3237': travelCategory,
  '3238': travelCategory,
  '3239': travelCategory,
  '3240': travelCategory,
  '3241': travelCategory,
  '3242': travelCategory,
  '3243': travelCategory,
  '3244': travelCategory,
  '3245': travelCategory,
  '3246': travelCategory,
  '3247': travelCategory,
  '3248': travelCategory,
  '3249': travelCategory,
  '3250': travelCategory,
  '3251': travelCategory,
  '3252': travelCategory,
  '3253': travelCategory,
  '3254': travelCategory,
  '3255': travelCategory,
  '3256': travelCategory,
  '3257': travelCategory,
  '3258': travelCategory,
  '3259': travelCategory,
  '3260': travelCategory,
  '3261': travelCategory,
  '3262': travelCategory,
  '3263': travelCategory,
  '3264': travelCategory,
  '3265': travelCategory,
  '3266': travelCategory,
  '3267': travelCategory,
  '3268': travelCategory,
  '3269': travelCategory,
  '3270': travelCategory,
  '3271': travelCategory,
  '3272': travelCategory,
  '3273': travelCategory,
  '3274': travelCategory,
  '3275': travelCategory,
  '3276': travelCategory,
  '3277': travelCategory,
  '3278': travelCategory,
  '3279': travelCategory,
  '3280': travelCategory,
  '3281': travelCategory,
  '3282': travelCategory,
  '3283': travelCategory,
  '3284': travelCategory,
  '3285': travelCategory,
  '3286': travelCategory,
  '3287': travelCategory,
  '3288': travelCategory,
  '3289': travelCategory,
  '3290': travelCategory,
  '3291': travelCategory,
  '3292': travelCategory,
  '3293': travelCategory,
  '3294': travelCategory,
  '3295': travelCategory,
  '3296': travelCategory,
  '3297': travelCategory,
  '3298': travelCategory,
  '3299': travelCategory,
  '3501': travelCategory,
  '3502': travelCategory,
  '3503': travelCategory,
  '3504': travelCategory,
  '3505': travelCategory,
  '3506': travelCategory,
  '3507': travelCategory,
  '3508': travelCategory,
  '3509': travelCategory,
  '3510': travelCategory,
  '3511': travelCategory,
  '3512': travelCategory,
  '3513': travelCategory,
  '3514': travelCategory,
  '3515': travelCategory,
  '3516': travelCategory,
  '3517': travelCategory,
  '3518': travelCategory,
  '3519': travelCategory,
  '3520': travelCategory,
  '3521': travelCategory,
  '3522': travelCategory,
  '3523': travelCategory,
  '3524': travelCategory,
  '3525': travelCategory,
  '3526': travelCategory,
  '3527': travelCategory,
  '3528': travelCategory,
  '3529': travelCategory,
  '3530': travelCategory,
  '3531': travelCategory,
  '3532': travelCategory,
  '3533': travelCategory,
  '3534': travelCategory,
  '3535': travelCategory,
  '3536': travelCategory,
  '3537': travelCategory,
  '3538': travelCategory,
  '3539': travelCategory,
  '3540': travelCategory,
  '3541': travelCategory,
  '3542': travelCategory,
  '3543': travelCategory,
  '3544': travelCategory,
  '3545': travelCategory,
  '3546': travelCategory,
  '3547': travelCategory,
  '3548': travelCategory,
  '3549': travelCategory,
  '3550': travelCategory,
  '3551': travelCategory,
  '3552': travelCategory,
  '3553': travelCategory,
  '3554': travelCategory,
  '3555': travelCategory,
  '3556': travelCategory,
  '3557': travelCategory,
  '3558': travelCategory,
  '3559': travelCategory,
  '3560': travelCategory,
  '3561': travelCategory,
  '3562': travelCategory,
  '3563': travelCategory,
  '3564': travelCategory,
  '3565': travelCategory,
  '3566': travelCategory,
  '3567': travelCategory,
  '3568': travelCategory,
  '3569': travelCategory,
  '3570': travelCategory,
  '3571': travelCategory,
  '3572': travelCategory,
  '3573': travelCategory,
  '3574': travelCategory,
  '3575': travelCategory,
  '3576': travelCategory,
  '3577': travelCategory,
  '3578': travelCategory,
  '3579': travelCategory,
  '3580': travelCategory,
  '3581': travelCategory,
  '3582': travelCategory,
  '3583': travelCategory,
  '3584': travelCategory,
  '3585': travelCategory,
  '3586': travelCategory,
  '3587': travelCategory,
  '3588': travelCategory,
  '3589': travelCategory,
  '3590': travelCategory,
  '3591': travelCategory,
  '3592': travelCategory,
  '3593': travelCategory,
  '3594': travelCategory,
  '3595': travelCategory,
  '3596': travelCategory,
  '3597': travelCategory,
  '3598': travelCategory,
  '3599': travelCategory,
  '3600': travelCategory,
  '3601': travelCategory,
  '3602': travelCategory,
  '3603': travelCategory,
  '3604': travelCategory,
  '3605': travelCategory,
  '3606': travelCategory,
  '3607': travelCategory,
  '3608': travelCategory,
  '3609': travelCategory,
  '3610': travelCategory,
  '3611': travelCategory,
  '3612': travelCategory,
  '3613': travelCategory,
  '3614': travelCategory,
  '3615': travelCategory,
  '3616': travelCategory,
  '3617': travelCategory,
  '3618': travelCategory,
  '3619': travelCategory,
  '3620': travelCategory,
  '3621': travelCategory,
  '3622': travelCategory,
  '3623': travelCategory,
  '3624': travelCategory,
  '3625': travelCategory,
  '3626': travelCategory,
  '3627': travelCategory,
  '3628': travelCategory,
  '3629': travelCategory,
  '3630': travelCategory,
  '3631': travelCategory,
  '3632': travelCategory,
  '3633': travelCategory,
  '3634': travelCategory,
  '3635': travelCategory,
  '3636': travelCategory,
  '3637': travelCategory,
  '3638': travelCategory,
  '3639': travelCategory,
  '3640': travelCategory,
  '3641': travelCategory,
  '3642': travelCategory,
  '3643': travelCategory,
  '3644': travelCategory,
  '3645': travelCategory,
  '3646': travelCategory,
  '3647': travelCategory,
  '3648': travelCategory,
  '3649': travelCategory,
  '3650': travelCategory,
  '3651': travelCategory,
  '3652': travelCategory,
  '3653': travelCategory,
  '3654': travelCategory,
  '3655': travelCategory,
  '3656': travelCategory,
  '3657': travelCategory,
  '3658': travelCategory,
  '3659': travelCategory,
  '3660': travelCategory,
  '3661': travelCategory,
  '3662': travelCategory,
  '3663': travelCategory,
  '3664': travelCategory,
  '3665': travelCategory,
  '3666': travelCategory,
  '3667': travelCategory,
  '3668': travelCategory,
  '3669': travelCategory,
  '3670': travelCategory,
  '3671': travelCategory,
  '3672': travelCategory,
  '3673': travelCategory,
  '3674': travelCategory,
  '3675': travelCategory,
  '3676': travelCategory,
  '3677': travelCategory,
  '3678': travelCategory,
  '3679': travelCategory,
  '3680': travelCategory,
  '3681': travelCategory,
  '3682': travelCategory,
  '3683': travelCategory,
  '3684': travelCategory,
  '3685': travelCategory,
  '3686': travelCategory,
  '3687': travelCategory,
  '3688': travelCategory,
  '3689': travelCategory,
  '3690': travelCategory,
  '3691': travelCategory,
  '3692': travelCategory,
  '3693': travelCategory,
  '3694': travelCategory,
  '3695': travelCategory,
  '3696': travelCategory,
  '3697': travelCategory,
  '3698': travelCategory,
  '3699': travelCategory,
  '3700': travelCategory,
  '3701': travelCategory,
  '3702': travelCategory,
  '3703': travelCategory,
  '3704': travelCategory,
  '3705': travelCategory,
  '3706': travelCategory,
  '3707': travelCategory,
  '3708': travelCategory,
  '3709': travelCategory,
  '3710': travelCategory,
  '3711': travelCategory,
  '3712': travelCategory,
  '3713': travelCategory,
  '3714': travelCategory,
  '3715': travelCategory,
  '3716': travelCategory,
  '3717': travelCategory,
  '3718': travelCategory,
  '3719': travelCategory,
  '3720': travelCategory,
  '3721': travelCategory,
  '3722': travelCategory,
  '3723': travelCategory,
  '3724': travelCategory,
  '3725': travelCategory,
  '3726': travelCategory,
  '3727': travelCategory,
  '3728': travelCategory,
  '3729': travelCategory,
  '3730': travelCategory,
  '3731': travelCategory,
  '3732': travelCategory,
  '3733': travelCategory,
  '3734': travelCategory,
  '3735': travelCategory,
  '3736': travelCategory,
  '3737': travelCategory,
  '3738': travelCategory,
  '3739': travelCategory,
  '3740': travelCategory,
  '3741': travelCategory,
  '3742': travelCategory,
  '3743': travelCategory,
  '3744': travelCategory,
  '3745': travelCategory,
  '3746': travelCategory,
  '3747': travelCategory,
  '3748': travelCategory,
  '3749': travelCategory,
  '3750': travelCategory,
  '3751': travelCategory,
  '3752': travelCategory,
  '3753': travelCategory,
  '3754': travelCategory,
  '3755': travelCategory,
  '3756': travelCategory,
  '3757': travelCategory,
  '3758': travelCategory,
  '3759': travelCategory,
  '3760': travelCategory,
  '3761': travelCategory,
  '3762': travelCategory,
  '3763': travelCategory,
  '3764': travelCategory,
  '3765': travelCategory,
  '3766': travelCategory,
  '3767': travelCategory,
  '3768': travelCategory,
  '3769': travelCategory,
  '3770': travelCategory,
  '3771': travelCategory,
  '3772': travelCategory,
  '3773': travelCategory,
  '3774': travelCategory,
  '3775': travelCategory,
  '3776': travelCategory,
  '3777': travelCategory,
  '3778': travelCategory,
  '3779': travelCategory,
  '3780': travelCategory,
  '3781': travelCategory,
  '3782': travelCategory,
  '3783': travelCategory,
  '3784': travelCategory,
  '3785': travelCategory,
  '3786': travelCategory,
  '3787': travelCategory,
  '3788': travelCategory,
  '3789': travelCategory,
  '3790': travelCategory,
  '3816': travelCategory,
  '3835': travelCategory,
  '4411': travelCategory,
  '4511': travelCategory,
  '4582': travelCategory,
  '4722': travelCategory,
  '4723': travelCategory,
  '7011': travelCategory,
  '7012': travelCategory,
  '7033': travelCategory,
  '3300': travelCategory,
  '3301': travelCategory,
  '3791': travelCategory,
  '3792': travelCategory,
  '3793': travelCategory,
  '3794': travelCategory,
  '3795': travelCategory,
  '3796': travelCategory,
  '3797': travelCategory,
  '3798': travelCategory,
  '3799': travelCategory,
  '3800': travelCategory,
  '3801': travelCategory,
  '3802': travelCategory,
  '3807': travelCategory,
  '3808': travelCategory,
  '3811': travelCategory,
  '3812': travelCategory,
  '3813': travelCategory,
  '3814': travelCategory,
  '3815': travelCategory,
  '3818': travelCategory,
  '3819': travelCategory,
  '3820': travelCategory,
  '3821': travelCategory,
  '3822': travelCategory,
  '3823': travelCategory,
  '3824': travelCategory,
  '3825': travelCategory,
  '3826': travelCategory,
  '3827': travelCategory,
  '3828': travelCategory,
  '3829': travelCategory,
  '3830': travelCategory,
  '3831': travelCategory,
  '3832': travelCategory,
  '3833': travelCategory,
  '3834': travelCategory,
  '3836': travelCategory,
  '3837': travelCategory,
  '3838': travelCategory,

  '3351': 'Car and Transport',
  '3352': 'Car and Transport',
  '3353': 'Car and Transport',
  '3354': 'Car and Transport',
  '3355': 'Car and Transport',
  '3356': 'Car and Transport',
  '3357': 'Car and Transport',
  '3358': 'Car and Transport',
  '3359': 'Car and Transport',
  '3360': 'Car and Transport',
  '3361': 'Car and Transport',
  '3362': 'Car and Transport',
  '3363': 'Car and Transport',
  '3364': 'Car and Transport',
  '3365': 'Car and Transport',
  '3366': 'Car and Transport',
  '3367': 'Car and Transport',
  '3368': 'Car and Transport',
  '3369': 'Car and Transport',
  '3370': 'Car and Transport',
  '3371': 'Car and Transport',
  '3372': 'Car and Transport',
  '3373': 'Car and Transport',
  '3374': 'Car and Transport',
  '3375': 'Car and Transport',
  '3376': 'Car and Transport',
  '3377': 'Car and Transport',
  '3378': 'Car and Transport',
  '3379': 'Car and Transport',
  '3380': 'Car and Transport',
  '3381': 'Car and Transport',
  '3382': 'Car and Transport',
  '3383': 'Car and Transport',
  '3384': 'Car and Transport',
  '3385': 'Car and Transport',
  '3386': 'Car and Transport',
  '3387': 'Car and Transport',
  '3388': 'Car and Transport',
  '3389': 'Car and Transport',
  '3390': 'Car and Transport',
  '3391': 'Car and Transport',
  '3392': 'Car and Transport',
  '3393': 'Car and Transport',
  '3394': 'Car and Transport',
  '3395': 'Car and Transport',
  '3396': 'Car and Transport',
  '3397': 'Car and Transport',
  '3398': 'Car and Transport',
  '3399': 'Car and Transport',
  '3400': 'Car and Transport',
  '3401': 'Car and Transport',
  '3402': 'Car and Transport',
  '3403': 'Car and Transport',
  '3404': 'Car and Transport',
  '3405': 'Car and Transport',
  '3406': 'Car and Transport',
  '3407': 'Car and Transport',
  '3408': 'Car and Transport',
  '3409': 'Car and Transport',
  '3410': 'Car and Transport',
  '3411': 'Car and Transport',
  '3412': 'Car and Transport',
  '3413': 'Car and Transport',
  '3414': 'Car and Transport',
  '3415': 'Car and Transport',
  '3416': 'Car and Transport',
  '3417': 'Car and Transport',
  '3418': 'Car and Transport',
  '3419': 'Car and Transport',
  '3420': 'Car and Transport',
  '3421': 'Car and Transport',
  '3422': 'Car and Transport',
  '3423': 'Car and Transport',
  '3424': 'Car and Transport',
  '3425': 'Car and Transport',
  '3426': 'Car and Transport',
  '3427': 'Car and Transport',
  '3428': 'Car and Transport',
  '3429': 'Car and Transport',
  '3430': 'Car and Transport',
  '3431': 'Car and Transport',
  '3432': 'Car and Transport',
  '3433': 'Car and Transport',
  '3434': 'Car and Transport',
  '3435': 'Car and Transport',
  '3436': 'Car and Transport',
  '3437': 'Car and Transport',
  '3438': 'Car and Transport',
  '3439': 'Car and Transport',
  '3440': 'Car and Transport',
  '3441': 'Car and Transport',
  '5013': 'Car and Transport',
  '5172': 'Car and Transport',
  '5511': 'Car and Transport',
  '5521': 'Car and Transport',
  '5531': 'Car and Transport',
  '5532': 'Car and Transport',
  '5533': 'Car and Transport',
  '5541': 'Car and Transport',
  '5542': 'Car and Transport',
  '5571': 'Car and Transport',
  '5592': 'Car and Transport',
  '5598': 'Car and Transport',
  '5599': 'Car and Transport',
  '5983': 'Car and Transport',
  '7512': 'Car and Transport',
  '7513': 'Car and Transport',
  '7519': 'Car and Transport',
  '7523': 'Car and Transport',
  '7531': 'Car and Transport',
  '7534': 'Car and Transport',
  '7535': 'Car and Transport',
  '7538': 'Car and Transport',
  '7542': 'Car and Transport',
  '7549': 'Car and Transport',
  '4011': 'Car and Transport',
  '4111': 'Car and Transport',
  '4112': 'Car and Transport',
  '4121': 'Car and Transport',
  '4131': 'Car and Transport',
  '4214': 'Car and Transport',
  '4457': 'Car and Transport',
  '4468': 'Car and Transport',
  '4784': 'Car and Transport',
  '4789': 'Car and Transport',
  '5551': 'Car and Transport',
  '5561': 'Car and Transport',
  '5552': 'Car and Transport',

  '5131': shoppingCategory,
  '5094': shoppingCategory,
  '5137': shoppingCategory,
  '5139': shoppingCategory,
  '5192': shoppingCategory,
  '5271': shoppingCategory,
  '5300': shoppingCategory,
  '5309': shoppingCategory,
  '5310': shoppingCategory,
  '5311': shoppingCategory,
  '5331': shoppingCategory,
  '5399': shoppingCategory,
  '5611': shoppingCategory,
  '5621': shoppingCategory,
  '5631': shoppingCategory,
  '5641': shoppingCategory,
  '5651': shoppingCategory,
  '5655': shoppingCategory,
  '5661': shoppingCategory,
  '5681': shoppingCategory,
  '5691': shoppingCategory,
  '5697': shoppingCategory,
  '5698': shoppingCategory,
  '5699': shoppingCategory,
  '5733': shoppingCategory,
  '5734': shoppingCategory,
  '5735': shoppingCategory,
  '5832': shoppingCategory,
  '5921': shoppingCategory,
  '5931': shoppingCategory,
  '5932': shoppingCategory,
  '5933': shoppingCategory,
  '5935': shoppingCategory,
  '5937': shoppingCategory,
  '5942': shoppingCategory,
  '5943': shoppingCategory,
  '5944': shoppingCategory,
  '5945': shoppingCategory,
  '5946': shoppingCategory,
  '5947': shoppingCategory,
  '5948': shoppingCategory,
  '5949': shoppingCategory,
  '5950': shoppingCategory,
  '5961': shoppingCategory,
  '5963': shoppingCategory,
  '5964': shoppingCategory,
  '5970': shoppingCategory,
  '5971': shoppingCategory,
  '5972': shoppingCategory,
  '5973': shoppingCategory,
  '5975': shoppingCategory,
  '5977': shoppingCategory,
  '5978': shoppingCategory,
  '5992': shoppingCategory,
  '5994': shoppingCategory,
  '5995': shoppingCategory,
  '5997': shoppingCategory,
  '5998': shoppingCategory,
  '5999': shoppingCategory,
  '7278': shoppingCategory,
  '7296': shoppingCategory,
  '5122': shoppingCategory,
  '5262': shoppingCategory,

  '5815': 'Culture and Entertainment',
  '5816': 'Culture and Entertainment',
  '5817': 'Culture and Entertainment',
  '5818': 'Culture and Entertainment',
  '7800': 'Culture and Entertainment',
  '7801': 'Culture and Entertainment',
  '7802': 'Culture and Entertainment',
  '7829': 'Culture and Entertainment',
  '7832': 'Culture and Entertainment',
  '7841': 'Culture and Entertainment',
  '7911': 'Culture and Entertainment',
  '7922': 'Culture and Entertainment',
  '7929': 'Culture and Entertainment',
  '7932': 'Culture and Entertainment',
  '7933': 'Culture and Entertainment',
  '7991': 'Culture and Entertainment',
  '7993': 'Culture and Entertainment',
  '7994': 'Culture and Entertainment',
  '7995': 'Culture and Entertainment',
  '7996': 'Culture and Entertainment',
  '7998': 'Culture and Entertainment',
  '9406': 'Culture and Entertainment',

  '4119': 'Healthcare and Sport',
  '5047': 'Healthcare and Sport',
  '5912': 'Healthcare and Sport',
  '5940': 'Healthcare and Sport',
  '5941': 'Healthcare and Sport',
  '5976': 'Healthcare and Sport',
  '7032': 'Healthcare and Sport',
  '7297': 'Healthcare and Sport',
  '7298': 'Healthcare and Sport',
  '7941': 'Healthcare and Sport',
  '7992': 'Healthcare and Sport',
  '7997': 'Healthcare and Sport',
  '7999': 'Healthcare and Sport',
  '8011': 'Healthcare and Sport',
  '8021': 'Healthcare and Sport',
  '8031': 'Healthcare and Sport',
  '8041': 'Healthcare and Sport',
  '8042': 'Healthcare and Sport',
  '8043': 'Healthcare and Sport',
  '8044': 'Healthcare and Sport',
  '8049': 'Healthcare and Sport',
  '8050': 'Healthcare and Sport',
  '8062': 'Healthcare and Sport',
  '8071': 'Healthcare and Sport',
  '8099': 'Healthcare and Sport',

  '5021': furnitureCategory,
  '5039': furnitureCategory,
  '5044': furnitureCategory,
  '5045': furnitureCategory,
  '5051': furnitureCategory,
  '5065': furnitureCategory,
  '5072': furnitureCategory,
  '5074': furnitureCategory,
  '5099': furnitureCategory,
  '5111': furnitureCategory,
  '5193': furnitureCategory,
  '5198': furnitureCategory,
  '5200': furnitureCategory,
  '5211': furnitureCategory,
  '5231': furnitureCategory,
  '5251': furnitureCategory,
  '5261': furnitureCategory,
  '5712': furnitureCategory,
  '5713': furnitureCategory,
  '5714': furnitureCategory,
  '5718': furnitureCategory,
  '5719': furnitureCategory,
  '5722': furnitureCategory,
  '5732': furnitureCategory,
  '5996': furnitureCategory,

  '5811': 'Restaurants and Bars',
  '5812': 'Restaurants and Bars',
  '5813': 'Restaurants and Bars',
  '5814': 'Restaurants and Bars',

  '4900': 'Bills and Utilities',
  '9211': 'Bills and Utilities',
  '9222': 'Bills and Utilities',
  '9223': 'Bills and Utilities',
  '9311': 'Bills and Utilities',
  '9399': 'Bills and Utilities',

  '6010': 'Cash',
  '6011': 'Cash',

  '5199': 'Food and Groceries',
  '5411': 'Food and Groceries',
  '5422': 'Food and Groceries',
  '5441': 'Food and Groceries',
  '5451': 'Food and Groceries',
  '5462': 'Food and Groceries',
  '5499': 'Food and Groceries',
  '5993': 'Food and Groceries',

  '742': 'Services',
  '763': 'Services',
  '780': 'Services',
  '1520': 'Services',
  '1711': 'Services',
  '1731': 'Services',
  '1740': 'Services',
  '1750': 'Services',
  '1761': 'Services',
  '1771': 'Services',
  '1799': 'Services',
  '2741': 'Services',
  '2791': 'Services',
  '2842': 'Services',
  '4215': 'Services',
  '4225': 'Services',
  '4829': 'Services',
  '6012': 'Services',
  '6051': 'Services',
  '6211': 'Services',
  '6300': 'Services',
  '6381': 'Services',
  '6399': 'Services',
  '6513': 'Services',
  '7210': 'Services',
  '7211': 'Services',
  '7216': 'Services',
  '7217': 'Services',
  '7221': 'Services',
  '7230': 'Services',
  '7251': 'Services',
  '7261': 'Services',
  '7273': 'Services',
  '7276': 'Services',
  '7277': 'Services',
  '7299': 'Services',
  '7311': 'Services',
  '7321': 'Services',
  '7332': 'Services',
  '7333': 'Services',
  '7338': 'Services',
  '7339': 'Services',
  '7342': 'Services',
  '7349': 'Services',
  '7361': 'Services',
  '7372': 'Services',
  '7375': 'Services',
  '7379': 'Services',
  '7392': 'Services',
  '7393': 'Services',
  '7394': 'Services',
  '7395': 'Services',
  '7399': 'Services',
  '7511': 'Services',
  '7622': 'Services',
  '7623': 'Services',
  '7629': 'Services',
  '7631': 'Services',
  '7641': 'Services',
  '7692': 'Services',
  '7699': 'Services',
  '8111': 'Services',
  '8211': 'Services',
  '8220': 'Services',
  '8241': 'Services',
  '8244': 'Services',
  '8249': 'Services',
  '8299': 'Services',
  '8351': 'Services',
  '8398': 'Services',
  '8641': 'Services',
  '8651': 'Services',
  '8661': 'Services',
  '8675': 'Services',
  '8699': 'Services',
  '8734': 'Services',
  '8911': 'Services',
  '8931': 'Services',
  '8999': 'Services',
  '9402': 'Services',
  '9405': 'Services',
  '9700': 'Services',
  '9701': 'Services',
  '9702': 'Services',
  '9950': 'Services',
  '4812': 'Services',
  '4813': 'Services',
  '4814': 'Services',
  '4815': 'Services',
  '4816': 'Services',
  '4821': 'Services',
  '4899': 'Services',
  '5960': 'Services',
  '5962': 'Services',
  '5965': 'Services',
  '5966': 'Services',
  '5967': 'Services',
  '5968': 'Services',
  '5969': 'Services',
  '6050': 'Services',
  '6532': 'Services',
  '6533': 'Services',
  '6536': 'Services',
  '6537': 'Services',
  '6538': 'Services',
  '6540': 'Services',
  '0742': 'Services',
  '0763': 'Services',
  '0780': 'Services',

  '5046': 'Miscellaneous',
  '5085': 'Miscellaneous',
  '5169': 'Miscellaneous',
}
