import axios from 'axios'
import { ApiBulkTransactionInput, ApiBulkTransactionOutput, Transaction } from '../interface/transactionInterface'
import getModifiers from './getModifiers'

export function getEstimates(data: Transaction[]): Promise<ApiBulkTransactionOutput[]> {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_CONNECT_API_URL + '/transaction/bulk',
      headers: {
        'x-api-key': process.env.REACT_APP_CONNECT_API_KEY,
      },
      data: {
        ...getModifiers(),
        transactions: mapTransactions(data),
      },
    })
      .then((res: any) => {
        resolve(res.data.transactions)
      })
      .catch((err: any) => {
        reject(`Error getting estimates from the API (${err})`)
      })
  })
}

function mapTransactions(data: Transaction[]): ApiBulkTransactionInput[] {
  const transactions: ApiBulkTransactionInput[] = []
  data.forEach((transaction: Transaction) => {
    transactions.push({
      price: Math.abs(+transaction.amount),
      mcc: transaction.mcc,
      merchant: transaction.merchant || '',
      currencyISO: localStorage.getItem('currency') || 'GBP',
      transaction_id: transaction.id,
      transaction_date: transaction.date,
    })
  })
  return transactions
}
