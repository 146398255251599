import { faChartPie, faHouse, faFileLines, IconDefinition, faGear, faTree } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, useNavigate } from 'react-router-dom'

export default function BottomNavBar() {
  type NavItem = {
    name: string
    icon: IconDefinition
    route: string
  }
  const navItems: Array<NavItem> = [
    { name: 'Home', icon: faHouse, route: '/' },
    { name: 'Insights', icon: faChartPie, route: '/insights' },
    { name: 'Report', icon: faFileLines, route: '/report' },
    { name: 'Removals', icon: faTree, route: '/removals' },
    { name: 'Settings', icon: faGear, route: '/settings' },
  ]

  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div id="bottomNavBar">
      {navItems.map((item: NavItem) => {
        return (
          <div
            key={item.name}
            className={`flexColumn clickable  ${location.pathname === item.route ? 'prText' : 'dgText'}`}
            onClick={() => navigate(item.route)}
          >
            <FontAwesomeIcon icon={item.icon} size="lg" />
            <div className="xsText">{item.name}</div>
          </div>
        )
      })}
    </div>
  )
}
